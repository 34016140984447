import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharepointService } from 'src/app/shared/services';
import { NewsDialogComponent } from 'src/app/shared/components/dialogs/news-dialog/news-dialog.component';
import { News } from 'generated-sources';

@Injectable({
  providedIn: 'root',
})
export class PopupNewsService {
  private dialogRef: MatDialogRef<NewsDialogComponent> | null = null;

  constructor(
    private dialog: MatDialog,
    private sharepointService: SharepointService,
  ) {}

  private fetchLatestNewsAndOpenDialog(readNews: boolean): void {
    this.sharepointService.getLatestNewsForEmployee(readNews).subscribe((newsItems) => {
      if (newsItems.length > 0) {
        this.openDialog(newsItems);
      }
    });
  }

  private openDialog(newsItems: News[]): void {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(NewsDialogComponent, { data: newsItems });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }
  }

  triggerPopupNewsByLogin() {
    this.fetchLatestNewsAndOpenDialog(false);
  }

  triggerPopupNewsByMenu() {
    this.fetchLatestNewsAndOpenDialog(true);
  }
}
