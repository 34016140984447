import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NetworkDialogComponent } from '../components/dialogs/network-dialog/network-dialog.component';

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { LoginService } from './login.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.hasValidToken()) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.authService.getAccessToken(),
        },
      });
    }

    return next.handle(request).pipe(
      tap({
        next: () => {
          // no implementation required
        },
        error: (err: unknown) => {
          if (err instanceof HttpErrorResponse) {
            // No Internet or HTTP-calls / service is unreachable
            if (err.status === 0) {
              this.openDialog({ error: '0', message: 'Controleer uw internetverbinding' });
              // Authentication / Need to log in
            } else if (err.status === 401 && !request.url.endsWith('token')) {
              this.loginService.logOut();
              this.router.navigate(['/login']);
              // Hosting
            } else if (err.status === 502) {
              this.openDialog({ error: '502', message: 'Controleer docker' });
              // Service unavailable
            } else if (err.status === 503) {
              this.openDialog({ error: '503', message: 'Service bezet of afwezig' });
            } else if (err.status >= 500) {
              this.openDialog({ message: 'Er is een probleem met de server opgetreden. Probeer het nogmaals.' });
            } else {
              return;
            }
          }
        },
      }),
    );
  }

  openDialog(data: { error?: string; message: string }): void {
    this.dialog.open(NetworkDialogComponent, {
      data: data,
    });
  }
}
