<div id="assignment-details">
  <h2>Inzet bewerken</h2>
  <div class="header-wrapper">
    <mat-accordion multi>
      <form id="form" #form="ngForm" (ngSubmit)="dirty && !contentForm.invalid && submit()" [formGroup]="contentForm">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> Inzetgegevens </mat-panel-title>
            <mat-panel-description>
              <mat-icon color="primary">assignment</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label> {{ "Medewerker" | translate }}</mat-label>
            <mat-select
              name="employee"
              formControlName="employee"
              [compareWith]="byId"
              (selectionChange)="onElementChange()"
              required
              #employee
            >
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="employeeFilter"
                  placeholderLabel="Zoek..."
                  noEntriesFoundLabel="'Geen medewerker gevonden'"
                  #employeeSelectSearch
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let employee of filteredEmployees | async | sort: 'firstName'" [value]="employee">
                {{ employee.firstName + ' ' + employee.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{"Project" | translate}}</mat-label>
            <mat-select
              name="project"
              formControlName="project"
              [compareWith]="byId"
              (selectionChange)="onElementChange()"
              required
              #project
            >
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="customerFilter"
                  placeholderLabel="Zoek..."
                  noEntriesFoundLabel="'Geen project gevonden'"
                  #projectSelectSearch
                ></ngx-mat-select-search>
              </mat-option>
              <mat-optgroup *ngFor="let cwp of filteredCustomers | async | sort: 'name'" [label]="cwp.customer.name">
                <mat-option *ngFor="let project of cwp.projects" [value]="project"> {{ project.name }} </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Uren per week</mat-label>
            <input
              type="number"
              matInput
              formControlName="hoursPerWeek"
              name="hoursPerWeek"
              required
              pattern="[0-9]{1,2}"
              (beforeinput)="acceptNumericOnly($event)"
            />
            <mat-error>Ongeldig aantal uren</mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "Startdatum" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="startDate"
              formControlName="startDate"
              (click)="startDate.open()"
              readonly
              name="startDate"
              (dateChange)="onElementChange()"
              required
            />
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
            <mat-error>Verplicht veld</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "Einddatum" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="endDate"
              formControlName="endDate"
              name="endDate"
              (click)="endDate.open()"
              readonly
              (dateChange)="onElementChange()"
            />
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>
          <mat-error class="invalidDateRange" *ngIf="contentForm.errors?.invalidDateRange"
            >Start- en einddatum zijn incorrect.
          </mat-error>
        </mat-expansion-panel>
        <div class="actions">
          <button type="reset" mat-raised-button (click)="cancel()">
            <mat-icon>close</mat-icon>
            Annuleren
          </button>
          <button type="submit" form="form" mat-raised-button color="primary" [disabled]="!dirty || form.invalid">
            <mat-icon>done</mat-icon>
            Opslaan
          </button>
        </div>
      </form>
    </mat-accordion>
  </div>
</div>
