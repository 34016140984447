<app-loader></app-loader>
<app-header (sidenavToggle)="sidenav.toggle()"></app-header>
<mat-sidenav-container>
  <mat-sidenav #sidenav [mode]="this.mode" [opened]="this.opened">
    <app-sidenav *ngIf="isLoggedIn()"></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
