/*eslint-disable*/
export const calendar = {
  1969: [[2, 19, 0], [3, 18, 0], [4, 17, 0], [5, 16, 0], [6, 15, 0], [7, 14, 0], [8, 12, 0], [9, 12, 0], [10, 10, 0], [11, 10, 0]],
  1970: [[2, 9, 1], [3, 8, 1], [4, 7, 1], [5, 6, 1], [6, 5, 1], [7, 4, 1], [8, 2, 1], [9, 2, 1], [10, 1, 1], [10, 30, 1], [0, 8, 0, 11, 30, 1], [1, 7, 0]],
  1971: [[1, 26, 2], [2, 28, 2], [3, 26, 2], [4, 26, 2], [5, 24, 2], [6, 24, 2], [7, 22, 2], [8, 21, 2], [9, 20, 2], [10, 19, 2], [11, 18, 2], [0, 28, 1]],
  1972: [[1, 16, 3], [2, 16, 3], [3, 14, 3], [4, 14, 3], [5, 12, 3], [6, 12, 3], [7, 10, 3], [8, 9, 3], [9, 8, 3], [10, 7, 3], [11, 6, 3], [0, 17, 2]],
  1973: [[1, 4, 4], [2, 6, 4], [3, 4, 4], [4, 4, 4], [5, 2, 4], [6, 1, 4], [6, 30, 4], [7, 29, 4], [8, 27, 4], [9, 27, 4], [10, 25, 4], [0, 5, 3, 11, 25, 4]],
  1974: [[0, 24, 5], [1, 23, 5], [2, 24, 5], [3, 23, 5], [4, 22, 5], [5, 21, 5], [6, 20, 5], [7, 19, 5], [8, 17, 5], [9, 16, 5], [10, 15, 5], [11, 15, 5]],
  1975: [[0, 13, 6], [1, 12, 6], [2, 13, 6], [3, 12, 6], [4, 12, 6], [5, 10, 6], [6, 10, 6], [7, 8, 6], [8, 6, 6], [9, 6, 6], [10, 4, 6], [11, 4, 6]],
  1976: [[0, 2, 7, 11, 22, 8], [1, 1, 7], [2, 1, 7], [2, 31, 7], [3, 30, 7], [4, 30, 7], [5, 28, 7], [6, 28, 7], [7, 26, 7], [8, 24, 7], [9, 24, 7], [10, 22, 7]],
  1977: [[11, 11, 9], [0, 20, 8], [1, 19, 8], [2, 20, 8], [3, 19, 8], [4, 19, 8], [5, 17, 8], [6, 17, 8], [7, 15, 8], [8, 14, 8], [9, 13, 8], [10, 12, 8]],
  1978: [[11, 1, 10], [0, 10, 9, 11, 30, 10], [1, 8, 9], [2, 10, 9], [3, 8, 9], [4, 8, 9], [5, 6, 9], [6, 6, 9], [7, 5, 9], [8, 3, 9], [9, 3, 9], [10, 1, 9]],
  1979: [[10, 20, 11], [11, 20, 11], [0, 29, 10], [1, 27, 10], [2, 29, 10], [3, 27, 10], [4, 27, 10], [5, 25, 10], [6, 25, 10], [7, 23, 10], [8, 22, 10], [9, 22, 10]],
  1980: [[10, 9, 12], [11, 8, 12], [0, 19, 11], [1, 17, 11], [2, 18, 11], [3, 16, 11], [4, 15, 11], [5, 14, 11], [6, 13, 11], [7, 12, 11], [8, 10, 11], [9, 10, 11]],
  1981: [[9, 28, 13], [10, 27, 13], [0, 7, 12, 11, 27, 13], [1, 5, 12], [2, 7, 12], [3, 5, 12], [4, 5, 12], [5, 3, 12], [6, 2, 12], [7, 1, 12], [7, 30, 12], [8, 29, 12]],
  1982: [[9, 18, 14], [10, 16, 14], [11, 16, 14], [0, 26, 13], [1, 24, 13], [2, 26, 13], [3, 24, 13], [4, 24, 13], [5, 22, 13], [6, 21, 13], [7, 20, 13], [8, 18, 13]],
  1983: [[9, 7, 15], [10, 5, 15], [11, 5, 15], [0, 15, 14], [1, 14, 14], [2, 15, 14], [3, 14, 14], [4, 13, 14], [5, 12, 14], [6, 11, 14], [7, 9, 14], [8, 8, 14]],
  1984: [[8, 26, 16], [9, 25, 16], [10, 23, 16], [0, 4, 15, 11, 23, 16], [1, 2, 15], [2, 3, 15], [3, 2, 15], [4, 2, 15], [4, 31, 15], [5, 30, 15], [6, 29, 15], [7, 27, 15]],
  1985: [[8, 15, 17], [9, 15, 17], [10, 13, 17], [11, 13, 17], [0, 22, 16], [1, 20, 16], [2, 22, 16], [3, 21, 16], [4, 20, 16], [5, 19, 16], [6, 18, 16], [7, 17, 16]],
  1986: [[8, 5, 18], [9, 4, 18], [10, 3, 18], [11, 2, 18], [0, 11, 17], [1, 10, 17], [2, 11, 17], [3, 10, 17], [4, 9, 17], [5, 8, 17], [6, 8, 17], [7, 6, 17]],
  1987: [[7, 25, 19], [8, 24, 19], [9, 23, 19], [10, 22, 19], [0, 1, 18, 11, 21, 19], [0, 30, 18], [2, 1, 18], [2, 30, 18], [3, 29, 18], [4, 28, 18], [5, 27, 18], [6, 26, 18]],
  1988: [[7, 13, 20], [8, 12, 20], [9, 11, 20], [10, 10, 20], [11, 10, 20], [0, 20, 19], [1, 18, 19], [2, 19, 19], [3, 17, 19], [4, 16, 19], [5, 15, 19], [6, 14, 19]],
  1989: [[7, 2, 21], [8, 1, 21], [8, 30, 21], [9, 30, 21], [10, 29, 21], [0, 8, 20, 11, 29, 21], [1, 7, 20], [2, 8, 20], [3, 7, 20], [4, 6, 20], [5, 4, 20], [6, 4, 20]],
  1990: [[6, 23, 22], [7, 21, 22], [8, 20, 22], [9, 19, 22], [10, 18, 22], [11, 18, 22], [0, 27, 21], [1, 26, 21], [2, 27, 21], [3, 26, 21], [4, 25, 21], [5, 23, 21]],
  1991: [[6, 12, 23], [7, 11, 23], [8, 9, 23], [9, 8, 23], [10, 7, 23], [11, 7, 23], [0, 16, 22], [1, 15, 22], [2, 17, 22], [3, 15, 22], [4, 15, 22], [5, 13, 22]],
  1992: [[6, 1, 24], [6, 30, 24], [7, 29, 24], [8, 27, 24], [9, 26, 24], [10, 25, 24], [0, 5, 23, 11, 25, 24], [1, 4, 23], [2, 5, 23], [3, 4, 23], [4, 3, 23], [5, 2, 23]],
  1993: [[5, 21, 25], [6, 20, 25], [7, 18, 25], [8, 17, 25], [9, 16, 25], [10, 14, 25], [11, 14, 25], [0, 23, 24], [1, 22, 24], [2, 24, 24], [3, 22, 24], [4, 22, 24]],
  1994: [[5, 10, 26], [6, 9, 26], [7, 8, 26], [8, 6, 26], [9, 6, 26], [10, 4, 26], [11, 3, 26], [0, 12, 25], [1, 11, 25], [2, 13, 25], [3, 12, 25], [4, 11, 25]],
  1995: [[4, 30, 27], [5, 29, 27], [6, 28, 27], [7, 27, 27], [8, 25, 27], [9, 25, 27], [10, 23, 27], [0, 2, 26, 11, 23, 27], [0, 31, 26], [2, 2, 26], [3, 1, 26], [3, 30, 26]],
  1996: [[4, 18, 28], [5, 17, 28], [6, 16, 28], [7, 15, 28], [8, 13, 28], [9, 13, 28], [10, 12, 28], [11, 11, 28], [0, 21, 27], [1, 19, 27], [2, 20, 27], [3, 18, 27]],
  1997: [[4, 7, 29], [5, 6, 29], [6, 5, 29], [7, 4, 29], [8, 2, 29], [9, 2, 29], [10, 1, 29], [11, 1, 29], [0, 10, 28, 11, 30, 29], [1, 8, 28], [2, 10, 28], [3, 8, 28]],
  1998: [[3, 27, 30], [4, 26, 30], [5, 25, 30], [6, 24, 30], [7, 23, 30], [8, 21, 30], [9, 21, 30], [10, 20, 30], [11, 19, 30], [0, 29, 29], [1, 27, 29], [2, 29, 29]],
  1999: [[3, 17, 31], [4, 16, 31], [5, 15, 31], [6, 14, 31], [7, 12, 31], [8, 11, 31], [9, 10, 31], [10, 9, 31], [11, 9, 31], [0, 18, 30], [1, 17, 30], [2, 18, 30]],
  2000: [[3, 6, 32], [4, 5, 32], [5, 3, 32], [6, 3, 32], [7, 1, 32], [7, 30, 32], [8, 28, 32], [9, 28, 32], [10, 27, 32], [0, 8, 31, 11, 27, 32], [1, 7, 31], [2, 7, 31]],
  2001: [[2, 26, 33], [3, 25, 33], [4, 24, 33], [5, 22, 33], [6, 22, 33], [7, 20, 33], [8, 18, 33], [9, 17, 33], [10, 16, 33], [11, 16, 33], [0, 26, 32], [1, 24, 32]],
  2002: [[2, 15, 34], [3, 14, 34], [4, 13, 34], [5, 12, 34], [6, 11, 34], [7, 10, 34], [8, 8, 34], [9, 7, 34], [10, 6, 34], [11, 5, 34], [0, 15, 33], [1, 13, 33]],
  2003: [[2, 4, 35], [3, 3, 35], [4, 2, 35], [5, 1, 35], [6, 1, 35], [6, 30, 35], [7, 29, 35], [8, 27, 35], [9, 26, 35], [10, 25, 35], [0, 4, 34, 11, 24, 35], [1, 2, 34]],
  2004: [[1, 21, 36], [2, 22, 36], [3, 20, 36], [4, 20, 36], [5, 19, 36], [6, 18, 36], [7, 17, 36], [8, 15, 36], [9, 15, 36], [10, 14, 36], [11, 13, 36], [0, 23, 35]],
  2005: [[1, 10, 37], [2, 11, 37], [3, 10, 37], [4, 9, 37], [5, 8, 37], [6, 7, 37], [7, 6, 37], [8, 5, 37], [9, 4, 37], [10, 3, 37], [11, 3, 37], [0, 12, 36]],
  2006: [[0, 31, 38], [2, 1, 38], [2, 30, 38], [3, 29, 38], [4, 28, 38], [5, 26, 38], [6, 26, 38], [7, 25, 38], [8, 24, 38], [9, 23, 38], [10, 22, 38], [0, 1, 37, 11, 22, 38]],
  2007: [[0, 20, 39], [1, 19, 39], [2, 20, 39], [3, 18, 39], [4, 18, 39], [5, 16, 39], [6, 15, 39], [7, 14, 39], [8, 13, 39], [9, 13, 39], [10, 11, 39], [11, 11, 39]],
  2008: [[0, 10, 40, 11, 29, 41], [1, 8, 40], [2, 9, 40], [3, 7, 40], [4, 6, 40], [5, 5, 40], [6, 4, 40], [7, 2, 40], [8, 1, 40], [9, 1, 40], [9, 30, 40], [10, 29, 40]],
  2009: [[11, 18, 42], [0, 27, 41], [1, 26, 41], [2, 28, 41], [3, 26, 41], [4, 25, 41], [5, 24, 41], [6, 23, 41], [7, 22, 41], [8, 20, 41], [9, 20, 41], [10, 18, 41]],
  2010: [[11, 7, 43], [0, 16, 42], [1, 15, 42], [2, 17, 42], [3, 15, 42], [4, 15, 42], [5, 13, 42], [6, 13, 42], [7, 11, 42], [8, 10, 42], [9, 9, 42], [10, 7, 42]],
  2011: [[10, 26, 44], [0, 5, 43, 11, 26, 44], [1, 4, 43], [2, 6, 43], [3, 5, 43], [4, 4, 43], [5, 3, 43], [6, 2, 43], [7, 1, 43], [7, 30, 43], [8, 29, 43], [9, 28, 43]],
  2012: [[10, 15, 45], [11, 14, 45], [0, 24, 44], [1, 23, 44], [2, 24, 44], [3, 22, 44], [4, 22, 44], [5, 21, 44], [6, 20, 44], [7, 19, 44], [8, 17, 44], [9, 17, 44]],
  2013: [[10, 4, 46], [11, 4, 46], [0, 13, 45], [1, 11, 45], [2, 13, 45], [3, 11, 45], [4, 11, 45], [5, 10, 45], [6, 9, 45], [7, 8, 45], [8, 7, 45], [9, 6, 45]],
  2014: [[9, 25, 47], [10, 23, 47], [0, 2, 46, 11, 23, 47], [1, 1, 46], [2, 2, 46], [3, 1, 46], [3, 30, 46], [4, 30, 46], [5, 28, 46], [6, 28, 46], [7, 27, 46], [8, 25, 46]],
  2015: [[9, 14, 48], [10, 13, 48], [11, 12, 48], [0, 21, 47], [1, 20, 47], [2, 21, 47], [3, 20, 47], [4, 19, 47], [5, 18, 47], [6, 17, 47], [7, 16, 47], [8, 14, 47]],
  2016: [[9, 2, 49], [10, 1, 49], [10, 30, 49], [0, 11, 48, 11, 30, 49], [1, 10, 48], [2, 10, 48], [3, 8, 48], [4, 8, 48], [5, 6, 48], [6, 6, 48], [7, 4, 48], [8, 2, 48]],
  2017: [[8, 21, 50], [9, 21, 50], [10, 19, 50], [11, 19, 50], [0, 29, 49], [1, 28, 49], [2, 29, 49], [3, 27, 49], [4, 27, 49], [5, 25, 49], [6, 24, 49], [7, 23, 49]],
  2018: [[8, 11, 51], [9, 10, 51], [10, 9, 51], [11, 8, 51], [0, 18, 50], [1, 17, 50], [2, 18, 50], [3, 17, 50], [4, 16, 50], [5, 15, 50], [6, 14, 50], [7, 12, 50]],
  2019: [[7, 31, 52], [8, 30, 52], [9, 29, 52], [10, 28, 52], [0, 7, 51, 11, 27, 52], [1, 6, 51], [2, 8, 51], [3, 6, 51], [4, 6, 51], [5, 4, 51], [6, 4, 51], [7, 2, 51]],
  2020: [[7, 20, 53], [8, 18, 53], [9, 18, 53], [10, 16, 53], [11, 16, 53], [0, 26, 52], [1, 25, 52], [2, 25, 52], [3, 24, 52], [4, 24, 52], [5, 22, 52], [6, 22, 52]],
  2021: [[7, 9, 54], [8, 8, 54], [9, 7, 54], [10, 6, 54], [11, 5, 54], [0, 14, 53], [1, 13, 53], [2, 14, 53], [3, 13, 53], [4, 13, 53], [5, 11, 53], [6, 11, 53]],
  2022: [[6, 30, 55], [7, 28, 55], [8, 27, 55], [9, 26, 55], [10, 25, 55], [0, 4, 54, 11, 25, 55], [1, 2, 54], [2, 4, 54], [3, 2, 54], [4, 2, 54], [4, 31, 54], [5, 30, 54]],
  2023: [[6, 19, 56], [7, 17, 56], [8, 16, 56], [9, 16, 56], [10, 15, 56], [11, 14, 56], [0, 23, 55], [1, 21, 55], [2, 23, 55], [3, 21, 55], [4, 21, 55], [5, 19, 55]],
  2024: [[6, 7, 57], [7, 5, 57], [8, 4, 57], [9, 4, 57], [10, 3, 57], [11, 3, 57], [0, 13, 56], [1, 11, 56], [2, 11, 56], [3, 10, 56], [4, 9, 56], [5, 7, 56]],
  2025: [[5, 26, 58], [6, 26, 58], [7, 24, 58], [8, 23, 58], [9, 23, 58], [10, 22, 58], [0, 1, 57, 11, 21, 58], [0, 31, 57], [2, 1, 57], [2, 30, 57], [3, 29, 57], [4, 28, 57]],
  2026: [[5, 16, 59], [6, 15, 59], [7, 14, 59], [8, 12, 59], [9, 12, 59], [10, 11, 59], [11, 10, 59], [0, 20, 58], [1, 18, 58], [2, 20, 58], [3, 18, 58], [4, 18, 58]],
  2027: [[5, 6, 60], [6, 5, 60], [7, 3, 60], [8, 2, 60], [9, 1, 60], [9, 31, 60], [10, 29, 60], [0, 9, 59, 11, 29, 60], [1, 8, 59], [2, 9, 59], [3, 8, 59], [4, 7, 59]],
  2028: [[4, 25, 61], [5, 24, 61], [6, 23, 61], [7, 22, 61], [8, 20, 61], [9, 19, 61], [10, 18, 61], [11, 17, 61], [0, 28, 60], [1, 26, 60], [2, 27, 60], [3, 26, 60]],
  2029: [[4, 14, 62], [5, 13, 62], [6, 13, 62], [7, 11, 62], [8, 10, 62], [9, 9, 62], [10, 7, 62], [11, 7, 62], [0, 16, 61], [1, 14, 61], [2, 16, 61], [3, 15, 61]],
  2030: [[4, 3, 63], [5, 2, 63], [6, 2, 63], [7, 1, 63], [7, 30, 63], [8, 29, 63], [9, 28, 63], [10, 26, 63], [0, 5, 62, 11, 26, 63], [1, 4, 62], [2, 5, 62], [3, 4, 62]],
  2031: [[3, 23, 64], [4, 22, 64], [5, 21, 64], [6, 21, 64], [7, 20, 64], [8, 18, 64], [9, 17, 64], [10, 16, 64], [11, 15, 64], [0, 24, 63], [1, 23, 63], [2, 24, 63]],
  2032: [[3, 11, 65], [4, 10, 65], [5, 9, 65], [6, 9, 65], [7, 8, 65], [8, 6, 65], [9, 6, 65], [10, 4, 65], [11, 4, 65], [0, 14, 64], [1, 12, 64], [2, 13, 64]],
  2033: [[3, 1, 66], [3, 30, 66], [4, 29, 66], [5, 28, 66], [6, 28, 66], [7, 26, 66], [8, 25, 66], [9, 24, 66], [10, 23, 66], [0, 2, 65, 11, 23, 66], [1, 1, 65], [2, 2, 65]],
  2034: [[2, 21, 67], [3, 20, 67], [4, 19, 67], [5, 17, 67], [6, 17, 67], [7, 15, 67], [8, 14, 67], [9, 13, 67], [10, 12, 67], [11, 12, 67], [0, 21, 66], [1, 20, 66]],
  2035: [[2, 11, 68], [3, 9, 68], [4, 9, 68], [5, 7, 68], [6, 6, 68], [7, 5, 68], [8, 3, 68], [9, 2, 68], [10, 1, 68], [11, 1, 68], [0, 11, 67, 11, 30, 68], [1, 9, 67]],
  2036: [[1, 28, 69], [2, 29, 69], [3, 27, 69], [4, 27, 69], [5, 25, 69], [6, 24, 69], [7, 23, 69], [8, 21, 69], [9, 20, 69], [10, 19, 69], [11, 19, 69], [0, 29, 68]],
  2037: [[1, 16, 70], [2, 18, 70], [3, 17, 70], [4, 16, 70], [5, 15, 70], [6, 14, 70], [7, 12, 70], [8, 11, 70], [9, 10, 70], [10, 8, 70], [11, 8, 70], [0, 17, 69]],
  2038: [[1, 5, 71], [2, 7, 71], [3, 6, 71], [4, 5, 71], [5, 4, 71], [6, 3, 71], [7, 2, 71], [7, 31, 71], [8, 30, 71], [9, 29, 71], [10, 27, 71], [0, 7, 70, 11, 27, 71]],
  2039: [[0, 26, 72], [1, 24, 72], [2, 26, 72], [3, 24, 72], [4, 24, 72], [5, 23, 72], [6, 22, 72], [7, 21, 72], [8, 19, 72], [9, 19, 72], [10, 17, 72], [11, 17, 72]],
  2040: [[0, 15, 73], [1, 14, 73], [2, 14, 73], [3, 13, 73], [4, 12, 73], [5, 11, 73], [6, 10, 73], [7, 9, 73], [8, 7, 73], [9, 7, 73], [10, 6, 73], [11, 5, 73]],
  2041: [[0, 4, 74, 11, 24, 75], [1, 2, 74], [2, 4, 74], [3, 2, 74], [4, 1, 74], [4, 31, 74], [5, 29, 74], [6, 29, 74], [7, 28, 74], [8, 26, 74], [9, 26, 74], [10, 25, 74]],
  2042: [[11, 14, 76], [0, 23, 75], [1, 21, 75], [2, 23, 75], [3, 21, 75], [4, 20, 75], [5, 19, 75], [6, 18, 75], [7, 17, 75], [8, 15, 75], [9, 15, 75], [10, 14, 75]],
  2043: [[11, 3, 77], [0, 12, 76], [1, 11, 76], [2, 12, 76], [3, 11, 76], [4, 10, 76], [5, 8, 76], [6, 8, 76], [7, 6, 76], [8, 4, 76], [9, 4, 76], [10, 3, 76]],
  2044: [[10, 21, 78], [0, 2, 77, 11, 21, 78], [0, 31, 77], [2, 1, 77], [2, 30, 77], [3, 29, 77], [4, 28, 77], [5, 26, 77], [6, 26, 77], [7, 24, 77], [8, 23, 77], [9, 22, 77]],
  2045: [[10, 10, 79], [11, 10, 79], [0, 19, 78], [1, 18, 78], [2, 20, 78], [3, 18, 78], [4, 18, 78], [5, 16, 78], [6, 15, 78], [7, 14, 78], [8, 12, 78], [9, 12, 78]],
  2046: [[9, 31, 80], [10, 29, 80], [0, 8, 79, 11, 28, 80], [1, 7, 79], [2, 9, 79], [3, 7, 79], [4, 7, 79], [5, 5, 79], [6, 5, 79], [7, 3, 79], [8, 2, 79], [9, 1, 79]],
  2047: [[9, 20, 81], [10, 19, 81], [11, 18, 81], [0, 27, 80], [1, 26, 80], [2, 27, 80], [3, 26, 80], [4, 26, 80], [5, 24, 80], [6, 24, 80], [7, 23, 80], [8, 21, 80]],
  2048: [[9, 9, 82], [10, 7, 82], [11, 7, 82], [0, 16, 81], [1, 15, 81], [2, 16, 81], [3, 14, 81], [4, 14, 81], [5, 12, 81], [6, 12, 81], [7, 11, 81], [8, 10, 81]],
  2049: [[8, 28, 83], [9, 28, 83], [10, 26, 83], [0, 5, 82, 11, 26, 83], [1, 3, 82], [2, 5, 82], [3, 3, 82], [4, 3, 82], [5, 2, 82], [6, 1, 82], [6, 31, 82], [7, 30, 82]],
  2050: [[8, 17, 84], [9, 17, 84], [10, 15, 84], [11, 15, 84], [0, 24, 83], [1, 23, 83], [2, 24, 83], [3, 22, 83], [4, 22, 83], [5, 20, 83], [6, 20, 83], [7, 19, 83]],
  2051: [[8, 6, 85], [9, 6, 85], [10, 5, 85], [11, 4, 85], [0, 14, 84], [1, 12, 84], [2, 14, 84], [3, 12, 84], [4, 11, 84], [5, 10, 84], [6, 9, 84], [7, 8, 84]],
  2052: [[7, 26, 86], [8, 24, 86], [9, 24, 86], [10, 22, 86], [0, 3, 85, 11, 22, 86], [1, 2, 85], [2, 2, 85], [3, 1, 85], [3, 30, 85], [4, 29, 85], [5, 28, 85], [6, 27, 85]],
  2053: [[7, 15, 87], [8, 13, 87], [9, 13, 87], [10, 11, 87], [11, 11, 87], [0, 21, 86], [1, 20, 86], [2, 21, 86], [3, 20, 86], [4, 19, 86], [5, 17, 86], [6, 17, 86]],
  2054: [[7, 5, 88], [8, 3, 88], [9, 2, 88], [10, 1, 88], [10, 30, 88], [0, 10, 87, 11, 30, 88], [1, 9, 87], [2, 10, 87], [3, 9, 87], [4, 9, 87], [5, 7, 87], [6, 6, 87]],
  2055: [[6, 25, 89], [7, 24, 89], [8, 22, 89], [9, 21, 89], [10, 20, 89], [11, 19, 89], [0, 29, 88], [1, 27, 88], [2, 29, 88], [3, 28, 88], [4, 28, 88], [5, 26, 88]],
  2056: [[6, 14, 90], [7, 12, 90], [8, 11, 90], [9, 10, 90], [10, 8, 90], [11, 8, 90], [0, 18, 89], [1, 17, 89], [2, 17, 89], [3, 16, 89], [4, 16, 89], [5, 14, 89]],
  2057: [[6, 3, 91], [7, 1, 91], [7, 31, 91], [8, 30, 91], [9, 29, 91], [10, 27, 91], [0, 6, 90, 11, 27, 91], [1, 5, 90], [2, 6, 90], [3, 5, 90], [4, 5, 90], [5, 3, 90]],
  2058: [[5, 22, 92], [6, 21, 92], [7, 20, 92], [8, 19, 92], [9, 18, 92], [10, 17, 92], [11, 17, 92], [0, 25, 91], [1, 24, 91], [2, 25, 91], [3, 24, 91], [4, 23, 91]],
  2059: [[5, 11, 93], [6, 11, 93], [7, 9, 93], [8, 8, 93], [9, 8, 93], [10, 6, 93], [11, 6, 93], [0, 15, 92], [1, 14, 92], [2, 15, 92], [3, 13, 92], [4, 13, 92]],
  2060: [[4, 31, 94], [5, 29, 94], [6, 28, 94], [7, 27, 94], [8, 26, 94], [9, 25, 94], [10, 24, 94], [0, 5, 93, 11, 24, 94], [1, 3, 93], [2, 4, 93], [3, 2, 93], [4, 1, 93]],
  2061: [[4, 20, 95], [5, 19, 95], [6, 18, 95], [7, 16, 95], [8, 15, 95], [9, 15, 95], [10, 13, 95], [11, 13, 95], [0, 23, 94], [1, 21, 94], [2, 23, 94], [3, 21, 94]],
  2062: [[4, 10, 96], [5, 8, 96], [6, 8, 96], [7, 6, 96], [8, 4, 96], [9, 4, 96], [10, 3, 96], [11, 2, 96], [0, 12, 95], [1, 10, 95], [2, 12, 95], [3, 11, 95]],
  2063: [[3, 30, 97], [4, 29, 97], [5, 27, 97], [6, 27, 97], [7, 25, 97], [8, 24, 97], [9, 23, 97], [10, 22, 97], [0, 1, 96, 11, 21, 97], [0, 31, 96], [2, 1, 96], [2, 31, 96]],
  2064: [[3, 18, 98], [4, 17, 98], [5, 16, 98], [6, 15, 98], [7, 14, 98], [8, 12, 98], [9, 12, 98], [10, 10, 98], [11, 9, 98], [0, 20, 97], [1, 18, 97], [2, 19, 97]],
  2065: [[3, 7, 99], [4, 6, 99], [5, 5, 99], [6, 5, 99], [7, 3, 99], [8, 2, 99], [9, 1, 99], [9, 31, 99], [10, 29, 99], [0, 8, 98, 11, 28, 99], [1, 6, 98], [2, 8, 98]],
  2066: [[2, 27, 100], [3, 25, 100], [4, 25, 100], [5, 24, 100], [6, 24, 100], [7, 22, 100], [8, 21, 100], [9, 20, 100], [10, 19, 100], [11, 18, 100], [0, 27, 99], [1, 25, 99]],
  2067: [[2, 16, 101], [3, 15, 101], [4, 14, 101], [5, 13, 101], [6, 13, 101], [7, 11, 101], [8, 10, 101], [9, 10, 101], [10, 8, 101], [11, 8, 101], [0, 16, 100], [1, 15, 100]],
  2068: [[2, 5, 102], [3, 3, 102], [4, 3, 102], [5, 1, 102], [6, 1, 102], [6, 30, 102], [7, 29, 102], [8, 28, 102], [9, 27, 102], [10, 26, 102], [0, 6, 101, 11, 25, 102], [1, 4, 101]],
  2069: [[1, 23, 103], [2, 24, 103], [3, 22, 103], [4, 22, 103], [5, 20, 103], [6, 20, 103], [7, 18, 103], [8, 17, 103], [9, 16, 103], [10, 15, 103], [11, 15, 103], [0, 24, 102]],
  2070: [[1, 12, 104], [2, 14, 104], [3, 12, 104], [4, 11, 104], [5, 10, 104], [6, 9, 104], [7, 8, 104], [8, 6, 104], [9, 5, 104], [10, 4, 104], [11, 4, 104], [0, 13, 103]],
  2071: [[1, 1, 105], [2, 3, 105], [3, 2, 105], [4, 1, 105], [4, 30, 105], [5, 29, 105], [6, 28, 105], [7, 26, 105], [8, 25, 105], [9, 24, 105], [10, 23, 105], [0, 2, 104, 11, 22, 105]],
  2072: [[0, 21, 106], [1, 20, 106], [2, 21, 106], [3, 19, 106], [4, 19, 106], [5, 17, 106], [6, 17, 106], [7, 15, 106], [8, 13, 106], [9, 13, 106], [10, 11, 106], [11, 11, 106]],
  2073: [[0, 9, 107, 11, 30, 108], [1, 8, 107], [2, 10, 107], [3, 9, 107], [4, 8, 107], [5, 7, 107], [6, 6, 107], [7, 5, 107], [8, 3, 107], [9, 2, 107], [10, 1, 107], [10, 30, 107]],
  2074: [[11, 19, 109], [0, 28, 108], [1, 27, 108], [2, 29, 108], [3, 27, 108], [4, 27, 108], [5, 26, 108], [6, 25, 108], [7, 23, 108], [8, 22, 108], [9, 21, 108], [10, 20, 108]],
  2075: [[11, 9, 110], [0, 18, 109], [1, 16, 109], [2, 18, 109], [3, 16, 109], [4, 16, 109], [5, 15, 109], [6, 14, 109], [7, 13, 109], [8, 11, 109], [9, 11, 109], [10, 9, 109]],
  2076: [[10, 27, 111], [0, 7, 110, 11, 27, 111], [1, 6, 110], [2, 6, 110], [3, 5, 110], [4, 4, 110], [5, 3, 110], [6, 2, 110], [7, 1, 110], [7, 30, 110], [8, 29, 110], [9, 29, 110]],
  2077: [null, null, [0, 26, 111], [1, 24, 111], [2, 25, 111], [3, 24, 111], [4, 23, 111], [5, 21, 111], [6, 21, 111], [7, 19, 111], [8, 18, 111], [9, 18, 111]],
  year: 1389
};