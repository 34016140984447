import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import Holidays, { HolidaysTypes } from 'date-holidays';
import moment, { Moment } from 'moment';
import { Observable, Subscription } from 'rxjs';
import { Week } from 'src/app/employee/hours-entry/week';
import { Logger } from 'src/app/shared/helper/logger';
import { Memoized } from 'src/app/shared/helper/memoize';
import { Assignment, Customer, DailyEffort, Employee } from 'src/app/shared/models';
import { AssignmentsService, EffortsService, FeedbackService, UserService } from 'src/app/shared/services';
import { HoursInputComponent, HoursInputValueType } from '../hours-input/hours-input.component';

class DailyEffortViewHolder {
  public effort: DailyEffort = new DailyEffort();
  public date: Moment;
  public isApproved = false;

  public get submitted() {
    return this.effort.submittedByDate && !this.effort.approvedByDate;
  }

  public get approved() {
    return this.effort.approvedByDate;
  }
}

class Weekday {
  events: HolidaysTypes.Holiday;
  date: Moment;
}

class AssignmentRow {
  public days: DailyEffortViewHolder[] = [];
  public assignment: Assignment;
  public hasBeenApproved: boolean;

  constructor(week: Week) {
    this.days = [...new Array(7)].map((_, i) => {
      const holder = new DailyEffortViewHolder();
      holder.date = moment(week.startOfWeek).add(i, 'days');
      return holder;
    });
  }

  public findHolder(date: Moment): DailyEffortViewHolder {
    return this.days.find((h) => date.isSame(h.date, 'day'));
  }

  public total(): number {
    return this.days.reduce((acc, holder) => acc + (holder.effort.hours || 0), 0);
  }
}

@Component({
  selector: 'app-week-hours',
  templateUrl: './week-hours.component.html',
  styleUrls: ['./week-hours.component.scss'],
})
export class WeekHoursComponent implements OnChanges, OnInit, OnDestroy, AfterViewChecked {
  public weekdays: Weekday[] = [];

  @Input() public week: Week;

  @Input() public submit: Observable<void>;

  @Output() submittable = new EventEmitter<DailyEffort[]>();

  public assignments: Assignment[] = [];
  public customers: Customer[] = [];
  public efforts: DailyEffort[] = [];
  public rows: AssignmentRow[] = [];

  private selectedAssignmentIds: Set<number> = new Set();
  private holidays = new Holidays('NL', { types: ['public', 'school'] });
  private logger = new Logger('WeekHoursComponent');

  private effortSubscription: Subscription;
  private submitSubscription: Subscription;
  private loggedInUser: Employee;

  constructor(
    private assignmentsService: AssignmentsService,
    private effortsService: EffortsService,
    private feedback: FeedbackService,
    private userService: UserService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // observe events caused by the parent, aka submit
    this.submitSubscription = this.submit.subscribe(() => {
      this.submitEfforts();
    });

    this.effortSubscription = this.effortsService.effortChanged.subscribe((e) => {
      this.logger.log('Observed effort update', e);
    });

    this.loggedInUser = this.userService.getLoggedInUser();
  }

  ngOnDestroy(): void {
    this.submitSubscription.unsubscribe();
    this.effortSubscription.unsubscribe();
  }

  /**
   * The model aka the week was changed by our parent
   */
  ngOnChanges(): void {
    this.fetch();
  }

  /**
   * Needed to mitigate "Expression has changed after it was checked" error
   * The showing of the placeholder for entering hours relies on whether the
   * DOM element is disabled or not, which too is programmatically determined
   */
  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  /**
   * Find all efforts in required month
   * @param date the first day of the month
   */
  @Memoized()
  private getMyEffortsForMonth(date: Moment): Observable<DailyEffort[]> {
    const to = moment(date).endOf('month');
    return this.effortsService.getMyEffortsForTimespan(date, to);
  }

  private getApproved(day: DailyEffortViewHolder): void {
    this.getMyEffortsForMonth(moment(day.date).startOf('month')).subscribe((efforts) => {
      const approvedEffortOnFirstDayOfWeek = efforts.find((effort) => !effort.deletable);
      day.isApproved = !!approvedEffortOnFirstDayOfWeek;
    });
  }

  private processAssignments(): void {
    const customersById: Record<string, Customer> = {};
    const customerIds = [];

    this.assignments.forEach((assignment) => {
      Array.prototype.push.apply(this.efforts, assignment.efforts);
      const customerId = assignment.project.customer.id;
      if (!(customerId in customersById)) {
        customersById[customerId] = assignment.project.customer;
        customerIds.push(customerId);
      } else {
        assignment.project.customer = customersById[customerId];
      }
      customersById[customerId].assignments.push(assignment);
    });
    // Sort customers on internal / external.
    this.customers = customerIds
      .map((id) => customersById[id])
      // eslint-disable-next-line sonarjs/no-nested-conditional
      .sort((a, b) => (a.internalTask === b.internalTask ? a.name.localeCompare(b.name) : b.internalTask ? -1 : 1));

    // Add rows. Add active external assignments
    // regardless of effort, add internal only if efforts exist
    this.customers.forEach((customer) => {
      customer.assignments.forEach((a) => {
        if (!customer.isInternal() || a.efforts.length) {
          this.addAssignmentRow(a);
        }
      });
    });
    // Add the empty row
    this.addAssignmentRow();
    this.submittable.emit(this.submittableEfforts);
  }

  /**
   * Reuse the component
   */
  private reset() {
    this.weekdays = [];
    this.rows = [];
    this.assignments = [];
    this.selectedAssignmentIds.clear();
    this.efforts = [];
    this.customers = [];
  }

  setStyle(data: DailyEffort): Record<string, string> {
    let styles = {};
    if (this.isNotUpdatedByLoggedInUser(data)) {
      styles = { 'background-color': '#FFFFB3', title: data.updatedBy };
    }
    return styles;
  }

  setTitle(data: DailyEffort): string {
    let title = '';
    if (this.isNotUpdatedByLoggedInUser(data)) {
      title = 'Laatst aangepast door: ' + data.updatedBy;
    }
    return title;
  }

  private isNotUpdatedByLoggedInUser(data: DailyEffort) {
    return data.hours && data.hours > 0 && data.updatedBy && data.updatedBy !== this.loggedInUser.userName;
  }

  private fetch(): void {
    this.reset();

    // collect the weekdays
    this.weekdays = [...new Array(7)].map((_, i) => {
      const date = moment(this.week.startOfWeek).add(i, 'days');
      const weekday = new Weekday();
      const events = this.holidays.isHoliday(date.toDate());
      //there should only be one holiday per day in Dutch calendar
      weekday.events = events ? events[0] : null;
      weekday.date = date;
      return weekday;
    });

    const monday = this.week.startOfWeek;
    const sunday = moment(monday).add(6, 'days');
    this.assignmentsService.getMyAssignmentsForTimespan(monday, sunday).subscribe((assignments: Assignment[]) => {
      this.assignments = assignments;
      this.processAssignments();
    });
  }

  setEffort(effort: DailyEffort): void {
    // apply efforts to assignments
    this.assignments.forEach((assignment) => {
      if (assignment.contains(effort)) {
        assignment.set(effort);
      }
    });

    this.rows.forEach((row) => {
      row.days.forEach((v) => {
        if (v.effort.id === effort.id) {
          v.effort = effort;
        }
      });
    });
    this.applyEffort(effort);
  }

  compareAssignment(o1: Assignment, o2: Assignment): boolean {
    return o1 && o2 && o1.equals(o2);
  }

  addAssignmentRow(assignment?: Assignment): AssignmentRow {
    const row = new AssignmentRow(this.week);
    this.rows.push(row);

    if (assignment) {
      row.assignment = assignment;
      this.selectedAssignmentIds.add(assignment.id);
      if (assignment.efforts.length > 0) {
        assignment.efforts.forEach((effort) => {
          const holder = row.findHolder(effort.day);
          if (holder) {
            holder.effort = effort;
          }
        });
      }
    }
    row.days.forEach((d) => {
      this.getApproved(d);
    });
    row.hasBeenApproved = row.days.every((d) => d.isApproved);
    return row;
  }

  hasWorkedAtHomeOnDay(day: Moment): boolean {
    let workedAtHome = false;
    this.efforts.forEach((effort) => {
      if (effort.day.isSame(day)) {
        workedAtHome = effort.workedFromHome;
      }
    });
    return workedAtHome;
  }

  initValueOfWorkAtHome(day: Weekday): boolean {
    const foundEffort = this.efforts.find((effort) => effort.day.isSame(day.date));

    if (typeof foundEffort === 'undefined') {
      return false;
    } else {
      return foundEffort.workedFromHome;
    }
  }

  disableWorkAtHome(day: Weekday): boolean {
    let disable = true;
    this.efforts.forEach((effort) => {
      if (
        effort.day.isSame(day.date) &&
        !effort.approved &&
        this.workFromHomeAllowedForAssignmentById(effort.assignmentId)
      ) {
        disable = false;
      }
    });
    return disable;
  }

  onWorkFromHomeSelected(day: Weekday, evt: MatCheckboxChange): void {
    this.efforts.forEach((effort) => {
      if (
        effort.id !== null &&
        effort.day.isSame(day.date) &&
        this.workFromHomeAllowedForAssignmentById(effort.assignmentId)
      ) {
        effort.workedFromHome = evt.checked;
        this.effortsService.createOrUpdate(effort).subscribe({
          next: (updatedEffort) => {
            this.setEffort(updatedEffort);
            effort = updatedEffort;
            this.submittable.emit(this.submittableEfforts);
          },
          error: (err) => {
            this.feedback.openErrorToast(err);

            if (effort.id) {
              // cancel frontend changes
              this.effortsService.get(effort.id).subscribe((e) => {
                effort = e;
              });
            } else {
              // reset frontend changes
              effort = new DailyEffort();
            }
          },
        });
      }
    });
  }

  workFromHomeAllowedForAssignmentById(assignmentId: number): boolean {
    const assignmentToCheck = this.assignments.find((assignment) => assignment.id === assignmentId);

    return assignmentToCheck?.project?.workFromHomeAllowed;
  }

  onProjectSelected(row: AssignmentRow, evt: MatSelectChange): void {
    if (row.assignment) {
      this.selectedAssignmentIds.delete(row.assignment.id);
    } else {
      this.addAssignmentRow();
    }
    row.assignment = evt.value;
    this.selectedAssignmentIds.add(row.assignment.id);
  }

  private get submittableEfforts() {
    return this.efforts.filter((effort) => !effort.submittedByDate);
  }

  isAssignmentSelected(assignment: Assignment): boolean {
    return this.selectedAssignmentIds.has(assignment.id);
  }

  onEffort(row: AssignmentRow, holder: DailyEffortViewHolder, weekday: Weekday, input: HoursInputValueType): void {
    const effort = holder.effort;
    const { hourPart, minutePart } = input.parts.controls;
    effort.hours = hourPart.value + (minutePart.value || 0) / 60;

    effort.assignmentId = row.assignment ? row.assignment.id : null;
    if (effort.day == null && this.workFromHomeAllowedForAssignmentById(effort.assignmentId)) {
      effort.workedFromHome = this.hasWorkedAtHomeOnDay(weekday.date);
    }
    effort.day = weekday.date;

    if (effort.hours > 0 && effort.assignmentId) {
      this.effortsService.createOrUpdate(effort).subscribe({
        next: (updatedEffort) => {
          this.setEffort(updatedEffort);
          holder.effort = updatedEffort;
          this.submittable.emit(this.submittableEfforts);
        },
        error: (err) => {
          this.feedback.openErrorToast(err);

          if (effort.id) {
            // cancel frontend changes
            this.effortsService.get(effort.id).subscribe((e) => {
              holder.effort = e;
            });
          } else {
            // reset frontend changes
            holder.effort = new DailyEffort();
          }
        },
      });
    } else if (effort.id) {
      // We must still emit the result. It could be that a previous entry has been removed
      this.submittable.emit(this.submittableEfforts.filter((e) => e.hours > 0));
      const tempEffort = effort;
      this.effortsService.delete(effort.id).subscribe(() => {
        holder.effort = new DailyEffort();
        this.efforts = this.removeFromEfforts(tempEffort);
      });
    } else {
      holder.effort = new DailyEffort();
    }
  }

  removeFromEfforts(effort: DailyEffort): DailyEffort[] {
    return this.efforts.filter(function (ele) {
      return ele !== effort;
    });
  }

  private applyEffort(needle: DailyEffort) {
    const idx = this.efforts.findIndex((e) => e.id === needle.id);
    if (idx >= 0) {
      this.efforts[idx] = needle;
    } else {
      this.efforts.push(needle);
    }
  }

  focus(e: FocusEvent): void {
    if (e.target instanceof HTMLInputElement) e.target.select();
  }

  dayTotal(day: Weekday): number {
    return this.rows
      .map((row) => row.findHolder(day.date))
      .map((holder) => holder.effort.hours || 0)
      .reduce((acc, hours) => acc + hours, 0);
  }

  total(): number {
    return this.rows.reduce((acc, row) => acc + row.total(), 0);
  }

  /**
   * disable selectbox when the complete week has been approved or when the selected assignment already
   * has efforts in this week
   */
  disableProjectSelect(row: AssignmentRow): boolean {
    return row.hasBeenApproved || row.assignment?.efforts?.length > 0;
  }

  determineDailyEffortInputPlaceholder(element: HoursInputComponent): string {
    return !element.empty || element.disabled ? '' : '00:00';
  }

  disableDailyEffortInput(row: AssignmentRow, holder: DailyEffortViewHolder): boolean {
    return !row.assignment ||
      row?.assignment?.endDate?.isBefore(holder.date, 'day') ||
      holder.submitted ||
      this.dayTotal({ date: holder.date, events: null }) >= 24
      ? true
      : holder.isApproved;
  }

  private submitEfforts() {
    this.effortsService.submitEfforts(this.submittableEfforts).subscribe({
      next: (response: DailyEffort[]) => {
        response.forEach((e) => {
          this.setEffort(e);
        });
        this.submittable.emit(this.submittableEfforts);
      },
      error: (err: unknown) => {
        this.feedback.openErrorToast(err);
      },
    });
  }
}
