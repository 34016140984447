import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  useArchiving: boolean;
  useDelete: boolean;
  info: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
  ) {
    // Update view with given values
    this.info = data.info;
    this.useArchiving = data.useArchiving;
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.useDelete = !this.useArchiving;
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  get confirmText(): string {
    return this.useArchiving ? 'Archiveren' : 'Verwijderen';
  }
}

export class ConfirmDialogModel {
  constructor(
    public info: string,
    public useArchiving: boolean,
  ) {}
}
