import { Model } from './model';
import { EmployeeListObject as EmployeeListObjectDTO } from 'generated-sources';

export class EmployeeListObject extends Model {
  id?: number;
  firstName: string;
  lastName: string;
  userName: string;
  holidayYear: number;
  deletable: boolean;
  lastMonthApproved?: boolean;
  hasContractEnded: boolean;

  constructor(raw: EmployeeListObjectDTO) {
    super();
    this.id = raw.id;
    this.firstName = raw.firstName;
    this.lastName = raw.lastName;
    this.userName = raw.userName;
    this.holidayYear = raw.holidayYear;
    this.deletable = !!raw.deletable;
    this.lastMonthApproved = raw.lastMonthApproved;
    this.hasContractEnded = raw.hasContractEnded;
  }

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
