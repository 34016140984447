import { Injectable } from '@angular/core';
import { Assignment as AssignmentDTO, AssignmentsService as Swagger, Configuration } from 'generated-sources';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { Assignment } from 'src/app/shared/models';
import { HttpService } from './http';
import { ResourceService } from './resource.service';
import { UserService } from './user.service';

@Injectable()
export class AssignmentsService extends ResourceService<Assignment, AssignmentDTO, Assignment, AssignmentDTO> {
  private readonly proxy: Swagger;

  constructor(
    private http: HttpService,
    private userService: UserService,
  ) {
    super(http);
    this.proxy = new Swagger(http.asClient, this.backend, new Configuration());
  }

  get employeeId(): number {
    return this.userService.getLoggedInUser().id;
  }

  fetch(id: number): Observable<Assignment> {
    return this.proxy.getByIdUsingGET(id).pipe(this.mapSingle);
  }

  protected get name(): string {
    return 'assignment';
  }

  create(assignment: Assignment): Observable<Assignment> {
    const dto = this.modelToDto(assignment);
    return this.proxy.createUsingPOST(dto).pipe(this.mapSingle);
  }

  update(id: number, assignment: Assignment): Observable<Assignment> {
    const dto = this.modelToDto(assignment);
    return this.proxy.updateUsingPUT(dto, dto.id).pipe(this.mapSingle);
  }

  delete(id: number): Observable<unknown> {
    return this.proxy.deleteUsingDELETE(id);
  }

  getMyAssignmentsForTimespan(from: Moment = moment(), until: Moment = moment()): Observable<Assignment[]> {
    const fromString = from ? from.format(this.dateFormat) : null;
    const untilString = until ? until.format(this.dateFormat) : null;

    return this.proxy.getMyAssignmentsUsingGET(fromString, untilString).pipe(this.mapList);
  }

  getAssignmentsForEmployee(
    id: number = this.employeeId,
    from: Moment = moment(),
    until: Moment = moment(),
  ): Observable<Assignment[]> {
    return this.proxy
      .getAssignmentsForEmployeeUsingGET(id, from.format(this.dateFormat), until.format(this.dateFormat))
      .pipe(this.mapList);
  }

  protected modelToDto(a: Assignment): AssignmentDTO {
    return {
      id: a.id,
      employeeId: a.employeeId,
      project: { id: a.project.id },
      hoursPerWeek: a.hoursPerWeek,
      startDate: a.startDateString,
      endDate: a.endDateString,
    } as AssignmentDTO;
  }

  protected dtoToModel(a: AssignmentDTO): Assignment {
    return new Assignment(a);
  }

  protected listDtoToModel(dto: AssignmentDTO): Assignment {
    return new Assignment(dto);
  }
}
