/**
 * XLence Urenregistatie API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DailyEffort } from '../model/dailyEffort';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DailyeffortsService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * approveForEmployee
     * 
     * @param body 
     * @param employeeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approveForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DailyEffort>>;
    public approveForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DailyEffort>>>;
    public approveForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DailyEffort>>>;
    public approveForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling approveForEmployeeUsingPUT.');
        }

        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling approveForEmployeeUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<DailyEffort>>('put',`${this.basePath}/services/effort/approve/${encodeURIComponent(String(employeeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUsingPOST1(body: DailyEffort, observe?: 'body', reportProgress?: boolean): Observable<DailyEffort>;
    public createUsingPOST1(body: DailyEffort, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DailyEffort>>;
    public createUsingPOST1(body: DailyEffort, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DailyEffort>>;
    public createUsingPOST1(body: DailyEffort, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createUsingPOST1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DailyEffort>('post',`${this.basePath}/services/effort`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUsingDELETE1(id: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteUsingDELETE1(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteUsingDELETE1(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteUsingDELETE1(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteUsingDELETE1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/services/effort/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export a range of efforts to a file
     * 
     * @param from Start date (inclusive) of the exported range
     * @param until End date (inclusive) of the exported range
     * @param employees List of employee IDs to export. If not given, all employees are exported.
     * @param projects List of project IDs to export. If not given, all projects are exported.
     * @param granularity Indicates on what time level efforts should be aggregated
     * @param outputFormat The output format to produce
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportUsingGET(from: string, until: string, employees?: Array<number>, projects?: Array<number>, granularity?: string, outputFormat?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportUsingGET(from: string, until: string, employees?: Array<number>, projects?: Array<number>, granularity?: string, outputFormat?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportUsingGET(from: string, until: string, employees?: Array<number>, projects?: Array<number>, granularity?: string, outputFormat?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportUsingGET(from: string, until: string, employees?: Array<number>, projects?: Array<number>, granularity?: string, outputFormat?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling exportUsingGET.');
        }

        if (until === null || until === undefined) {
            throw new Error('Required parameter until was null or undefined when calling exportUsingGET.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (until !== undefined && until !== null) {
            queryParameters = queryParameters.set('until', <any>until);
        }
        if (employees) {
            employees.forEach((element) => {
                queryParameters = queryParameters.append('employees', <any>element);
            })
        }
        if (projects) {
            projects.forEach((element) => {
                queryParameters = queryParameters.append('projects', <any>element);
            })
        }
        if (granularity !== undefined && granularity !== null) {
            queryParameters = queryParameters.set('granularity', <any>granularity);
        }
        if (outputFormat !== undefined && outputFormat !== null) {
            queryParameters = queryParameters.set('outputFormat', <any>outputFormat);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request('get',`${this.basePath}/services/effort/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getForTimespan
     * 
     * @param id 
     * @param from 
     * @param until 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getForTimespanUsingGET(id: number, from: string, until: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DailyEffort>>;
    public getForTimespanUsingGET(id: number, from: string, until: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DailyEffort>>>;
    public getForTimespanUsingGET(id: number, from: string, until: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DailyEffort>>>;
    public getForTimespanUsingGET(id: number, from: string, until: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getForTimespanUsingGET.');
        }

        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getForTimespanUsingGET.');
        }

        if (until === null || until === undefined) {
            throw new Error('Required parameter until was null or undefined when calling getForTimespanUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (until !== undefined && until !== null) {
            queryParameters = queryParameters.set('until', <any>until);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DailyEffort>>('get',`${this.basePath}/services/effort/employee/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getMineForTimespan
     * 
     * @param from 
     * @param until 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMineForTimespanUsingGET(from?: string, until?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DailyEffort>>;
    public getMineForTimespanUsingGET(from?: string, until?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DailyEffort>>>;
    public getMineForTimespanUsingGET(from?: string, until?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DailyEffort>>>;
    public getMineForTimespanUsingGET(from?: string, until?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (until !== undefined && until !== null) {
            queryParameters = queryParameters.set('until', <any>until);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DailyEffort>>('get',`${this.basePath}/services/effort/employee`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getOne
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOneUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<DailyEffort>;
    public getOneUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DailyEffort>>;
    public getOneUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DailyEffort>>;
    public getOneUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOneUsingGET.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DailyEffort>('get',`${this.basePath}/services/effort/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * resetForEmployee
     * 
     * @param body 
     * @param employeeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DailyEffort>>;
    public resetForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DailyEffort>>>;
    public resetForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DailyEffort>>>;
    public resetForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling resetForEmployeeUsingPUT.');
        }

        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling resetForEmployeeUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<DailyEffort>>('put',`${this.basePath}/services/effort/reset/${encodeURIComponent(String(employeeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * submitForEmployee
     * 
     * @param body 
     * @param employeeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public submitForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DailyEffort>>;
    public submitForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DailyEffort>>>;
    public submitForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DailyEffort>>>;
    public submitForEmployeeUsingPUT(body: Array<DailyEffort>, employeeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling submitForEmployeeUsingPUT.');
        }

        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling submitForEmployeeUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<DailyEffort>>('put',`${this.basePath}/services/effort/submit/${encodeURIComponent(String(employeeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * submit
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public submitUsingPUT(body: Array<DailyEffort>, observe?: 'body', reportProgress?: boolean): Observable<Array<DailyEffort>>;
    public submitUsingPUT(body: Array<DailyEffort>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DailyEffort>>>;
    public submitUsingPUT(body: Array<DailyEffort>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DailyEffort>>>;
    public submitUsingPUT(body: Array<DailyEffort>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling submitUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<DailyEffort>>('put',`${this.basePath}/services/effort/submit`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUsingPUT1(body: DailyEffort, id: number, observe?: 'body', reportProgress?: boolean): Observable<DailyEffort>;
    public updateUsingPUT1(body: DailyEffort, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DailyEffort>>;
    public updateUsingPUT1(body: DailyEffort, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DailyEffort>>;
    public updateUsingPUT1(body: DailyEffort, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateUsingPUT1.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateUsingPUT1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerToken) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DailyEffort>('put',`${this.basePath}/services/effort/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
