import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Params, Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { DailyEffort } from 'src/app/shared/models';
import { FeedbackService, LocaleService } from 'src/app/shared/services';
import { Week } from './week';

@Component({
  selector: 'app-hours-entry',
  templateUrl: './hours-entry.component.html',
  styleUrls: ['./hours-entry.component.scss'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class HoursEntryComponent implements OnInit {
  // shown in the dropdown
  weeks: Week[];

  @ViewChild(MatSelect, { static: true }) weekSelect: MatSelect;

  // the week as passed to the week-hours component
  private week: Week;

  // event instructing week-hours component to submit hours
  public submit = new EventEmitter<unknown>();

  // boolean to determine if submit button is to be enabled
  public hasSubmittableHours = false;

  // URL params to handle week changes
  private params: Params;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private dateAdapter: DateAdapter<Moment>,
    private feedback: FeedbackService,
    private localeService: LocaleService,
  ) {
    this.dateAdapter.setLocale(localeService.getLocale());

    this.route.params.subscribe((params) => {
      this.params = params;
    });
  }

  ngOnInit(): void {
    if (this.params.year && this.params.week) {
      const m = moment().year(this.params.year).week(this.params.week);
      this.selectedWeekValue = new Week(m);
    } else {
      this.selectedWeekValue = new Week();
    }
  }

  // retrieve the selected week
  public get selectedWeekValue(): Week {
    return this.week;
  }

  // set the selected week and update the view
  public set selectedWeekValue(week: Week) {
    this.week = week;
    this.updateWeeksInDropdown();
    this.navigateTo(this.week);
  }

  // compare the selected week to the current Moment to determine if it is the current week
  public isThisWeek(): boolean {
    return new Week().equals(this.week);
  }

  // react to week-hours handing us the list of submittable hours
  public onSubmittable(submittableEfforts: DailyEffort[]): void {
    this.hasSubmittableHours = submittableEfforts.length > 0;
  }

  // update the URL to reflect week changes
  private navigateTo(week: Week) {
    const m = week.startOfWeek;
    this.router.navigate(['/medewerker/uren/', m.weekYear(), m.week()]);
  }

  // Reset the selected week to the current week
  public reset(): void {
    this.selectedWeekValue = new Week();
  }

  // move selected week to a week defined by a date, used by the datepicker
  public setDate(value: Moment): void {
    const moveTo = this.mondayOfDate(value);
    this.selectedWeekValue = new Week(moveTo);
  }

  // get the Moment for monday of week
  private mondayOfDate(date?: Moment): Moment {
    return moment(date).weekday(1);
  }

  // move back a week
  public previousWeek(): void {
    this.selectedWeekValue = new Week(moment(this.week.startOfWeek).subtract(7, 'days'));
  }

  // move forward a week
  public nextWeek(): void {
    this.selectedWeekValue = new Week(moment(this.week.startOfWeek).add(7, 'days'));
  }

  // update the week range in the dropdown
  private updateWeeksInDropdown() {
    const minus10 = moment(this.week.startOfWeek).subtract(70, 'days');
    this.weeks = [];
    for (let w = -10; w <= 10; w++) {
      const m = moment(minus10);
      this.weeks.push(new Week(m));
      minus10.add(7, 'days');
    }
  }

  // compare two weeks
  public compareWeek(a: Week, b: Week): boolean {
    return a.equals(b);
  }

  // filter dates to show only selectable mondays
  dateFilter = (d: Moment): boolean => {
    const day = d?.day();
    return day === 1;
  };

  // the button to submit hours was pressed
  public submitEfforts(): void {
    this.feedback.submitConfirmation().subscribe((dialogResult) => {
      this.onDialogResult(dialogResult);
    });
  }

  // instruct current week component to submit
  private onDialogResult(dialogResult: boolean) {
    if (dialogResult) {
      this.submit.emit(this.week);
      // set the selectedWeekValue with the same value to force a refresh of the week-hours
      this.selectedWeekValue = new Week(this.selectedWeekValue.startOfWeek);
    }
  }

  public weekStart(week: Week): string {
    return moment(week.startOfWeek).day(1).format('DD MMM');
  }

  public weekEnd(week: Week): string {
    return moment(week.startOfWeek).day(7).format('DD MMM');
  }
}
