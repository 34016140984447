import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-holiday-hours-dialog',
  templateUrl: './holiday-hours-dialog.component.html',
  styleUrls: ['./holiday-hours-dialog.component.scss'],
})
export class HolidayHoursDialogComponent implements OnInit {
  id: number;

  constructor(
    public dialogRef: MatDialogRef<HolidayHoursDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    private router: Router,
  ) {
    // Update view with given values
    this.id = data.id;
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
    this.router.navigate(['/admin/medewerker', this.id]);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
