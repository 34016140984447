<div id="customer-edit">
  <h2>Klant bewerken</h2>
  <div class="header-wrapper">
    <mat-accordion multi>
      <form
        id="form"
        #editCustomerForm="ngForm"
        (change)="dirty = true"
        (ngSubmit)="dirty && !editCustomerForm.invalid && submit()"
        [formGroup]="contentForm"
      >
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> Klantgegevens </mat-panel-title>
            <mat-panel-description>
              <mat-icon color="primary">work</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label>{{"Naam" | translate}}</mat-label>
            <input type="text" matInput formControlName="name" (keyup)="keyup($event)" required trim="blur" #name />
            <mat-error *ngIf="contentForm.controls.name.errors?.required">Veld moet worden ingevuld</mat-error>
          </mat-form-field>
        </mat-expansion-panel>
        <div class="actions">
          <button type="reset" mat-raised-button (click)="cancel()">
            <mat-icon>close</mat-icon>
            Annuleren
          </button>
          <button
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!dirty || editCustomerForm.invalid"
            (click)="submit()"
          >
            <mat-icon>done</mat-icon>
            Opslaan
          </button>
        </div>
      </form>
    </mat-accordion>
  </div>
</div>
