import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { UserRole } from 'src/app/shared/enums/UserRole';
import { UraValidators } from 'src/app/shared/helper/ura-validators';
import { Employee } from 'src/app/shared/models';
import { EmployeesService, FeedbackService, LocaleService } from 'src/app/shared/services';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['../../general.component.scss', './employee-edit.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class EmployeeEditComponent implements OnInit, AfterViewInit {
  dirty = false;

  employee: Employee = <Employee>{};
  currentHolidayHoursProRata: number;
  contentForm: UntypedFormGroup;
  isAdministrator: boolean;
  isAccounting: boolean;
  isContentManager: boolean;
  isEmployee: boolean;

  // ViewChild for autofocus, as of 3-3-2020 the autofocus attribute in html is still broken and stops working after cancelling the form once
  // and then reentering te form, in order to keep it working this has been implemented
  // See: https://stackoverflow.com/questions/56394068/autofocus-not-working-after-remove-and-adding-again-to-the-dom
  @ViewChild('userName', { static: true }) focusElement: ElementRef;

  email_pattern = '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$';
  hours_pattern = '^[1-9][0-9]?$';
  holiday_pattern = '^-?\\d{0,3}(\\.\\d{1,2})?$';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public feedback: FeedbackService,
    public employeesService: EmployeesService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private dateAdapter: DateAdapter<Moment>,
    localeService: LocaleService,
  ) {
    this.dateAdapter.setLocale(localeService.getLocale());
  }

  ngOnInit(): void {
    this.initForm();
    this.route.params.subscribe((params) => {
      this.getEmployee(params.id);
    });
  }

  initForm(): void {
    this.contentForm = this.formBuilder.group({
      userName: [{ value: '', disabled: true }],
      nickName: ['', Validators.compose([Validators.required, Validators.maxLength(40)])],
      firstName: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      lastName: ['', Validators.compose([Validators.required, Validators.maxLength(40)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.email_pattern)])],
      contractDate: ['', [Validators.required]],
      endDate: ['', []],
      contractHours: [
        '',
        Validators.compose([Validators.required, Validators.max(40), Validators.pattern(this.hours_pattern)]),
      ],
      holidayHoursPreviousYear: [
        '',
        Validators.compose([Validators.required, Validators.pattern(this.holiday_pattern)]),
      ],
      holidayHoursCurrentYear: [
        '',
        Validators.compose([Validators.required, Validators.pattern(this.holiday_pattern)]),
      ],
      holidayHoursCurrentYearProRata: [{ value: '', disabled: true }],
      approvedHolidayHours: ['', Validators.compose([Validators.required, Validators.pattern(this.holiday_pattern)])],
      submittedHolidayHours: ['', Validators.compose([Validators.required, Validators.pattern(this.holiday_pattern)])],
      currentHolidayHoursProRata: [{ value: '', disabled: true }],
      roles: this.formBuilder.group(
        {
          administrator: [],
          accounting: [],
          contentManager: [],
          employee: [],
        },
        { validator: UraValidators.rolesRequired } as AbstractControlOptions,
      ),
    });
    this.onChanges();
  }

  acceptNumericOnly(event: InputEvent): void {
    if (!/^[0-9,]*$/.test(event.data || '')) {
      event.preventDefault();
    }
    this.dirty = true;
  }

  onSubmit(): void {
    this.updateEmployee();
    this.employeesService.createOrUpdate(this.employee).subscribe({
      next: () => {
        this.feedback.openSuccessToast('De wijzigingen zijn opgeslagen');
        this.router.navigate(['/admin/medewerkers']);
      },
      error: (err) => {
        this.feedback.openErrorToast(err);
      },
    });
  }

  cancel(): void {
    this.feedback.openNeutralToast();
    this.router.navigate(['/admin/medewerkers']);
  }

  ngAfterViewInit(): void {
    this.focusElement.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  clearEndDate(): void {
    this.contentForm.get('endDate').reset();
  }

  isPastEmployment(): boolean {
    const endDate = this.contentForm.get('endDate').value;
    return endDate && moment(endDate).endOf('day').isBefore(moment());
  }

  isPastHolidayYear(): boolean {
    const holidayYear = this.employee.holidayYear;
    const currentYear = moment().year();
    return !this.isPastEmployment() && holidayYear < currentYear;
  }

  isCurrentHolidayYear(): boolean {
    const holidayYear = this.employee.holidayYear;
    const currentYear = moment().year();
    return !this.isPastEmployment() && holidayYear === currentYear;
  }

  holidayYear(): number {
    return this.employee.holidayYear;
  }

  rolloverHoliday(): void {
    const yearly = this.employee.holidayHoursCurrentYear;
    const previous = this.employee.holidayHoursPreviousYear;
    const approved = this.employee.approvedHolidayHours;

    const balance = yearly + previous - approved;

    this.contentForm.get('holidayHoursPreviousYear').setValue(balance);
    this.contentForm.get('approvedHolidayHours').setValue(0);
    this.contentForm.get('submittedHolidayHours').setValue(0);
    this.employee.holidayYear = moment().year();
    this.dirty = true;
  }

  private onChanges(): void {
    this.contentForm.get('holidayHoursPreviousYear').valueChanges.subscribe(() => {
      this.updateCurrentHolidayHours();
    });
    this.contentForm.get('holidayHoursCurrentYear').valueChanges.subscribe(() => {
      this.updateCurrentHolidayHours();
    });
    this.contentForm.get('approvedHolidayHours').valueChanges.subscribe(() => {
      this.updateCurrentHolidayHours();
    });
    this.contentForm.get('submittedHolidayHours').valueChanges.subscribe(() => {
      this.updateCurrentHolidayHours();
    });
    this.contentForm.get('contractDate').valueChanges.subscribe((value) => {
      if (this.employee.contractDate !== value) {
        this.dirty = true;
      }
    });

    this.contentForm.get('endDate').valueChanges.subscribe((value) => {
      if (this.employee.endDate !== value) {
        this.dirty = true;
      }
      this.updateCurrentHolidayHours();
    });
  }

  private initFormValues() {
    this.contentForm.controls['userName'].setValue(this.employee.userName);
    this.contentForm.controls['nickName'].setValue(this.employee.nickName);
    this.contentForm.controls['firstName'].setValue(this.employee.firstName);
    this.contentForm.controls['lastName'].setValue(this.employee.lastName);
    this.contentForm.controls['email'].setValue(this.employee.email);
    this.contentForm.controls['contractDate'].setValue(this.employee.contractDate);
    this.contentForm.controls['endDate'].setValue(this.employee.endDate);
    this.contentForm.controls['contractHours'].setValue(this.employee.contractHours);
    this.contentForm.controls['holidayHoursPreviousYear'].setValue(this.employee.holidayHoursPreviousYear);
    this.contentForm.controls['holidayHoursCurrentYear'].setValue(this.employee.holidayHoursCurrentYear);
    this.contentForm.controls['approvedHolidayHours'].setValue(this.employee.approvedHolidayHours);
    this.contentForm.controls['submittedHolidayHours'].setValue(this.employee.submittedHolidayHours);
    this.contentForm.controls['roles'].get('administrator').setValue(this.isAdministrator);
    if (this.employee.userName === 'Admin') {
      this.contentForm.controls['roles'].get('administrator').disable();
    }
    this.contentForm.controls['roles'].get('accounting').setValue(this.isAccounting);
    this.contentForm.controls['roles'].get('contentManager').setValue(this.isContentManager);
    this.contentForm.controls['roles'].get('employee').setValue(this.isEmployee);
  }

  private getEmployee(id: number) {
    this.employeesService.get(id).subscribe((response) => {
      this.employee = response;
      this.isAdministrator = this.employee.roles.indexOf(UserRole.ADMINISTRATOR) !== -1;
      this.isAccounting = this.employee.roles.indexOf(UserRole.ACCOUNTING) !== -1;
      this.isContentManager = this.employee.roles.indexOf(UserRole.CONTENT_MANAGER) !== -1;
      this.isEmployee = this.employee.roles.indexOf(UserRole.EMPLOYEE) !== -1;
      this.initFormValues();
      this.updateCurrentHolidayHours();
    });
  }

  private updateCurrentHolidayHours(): void {
    const hoursCarriedForward = this.contentForm.controls['holidayHoursPreviousYear'].value;
    const hoursApproved = this.contentForm.controls['approvedHolidayHours'].value;
    const endDate = this.contentForm.controls['endDate'].value && moment(this.contentForm.controls['endDate'].value);

    let proRateMonth: number;
    if (this.isPastEmployment()) {
      proRateMonth = endDate.month();
    } else if (this.isPastHolidayYear()) {
      proRateMonth = 11;
    } else {
      proRateMonth = new Date().getMonth();
    }
    const monthRate = (proRateMonth + 1) / 12;
    const currentYearProRata = Math.floor(this.contentForm.controls['holidayHoursCurrentYear'].value * monthRate);
    this.currentHolidayHoursProRata = hoursCarriedForward + currentYearProRata - hoursApproved;

    this.contentForm.controls['holidayHoursCurrentYearProRata'].setValue(currentYearProRata);
    this.contentForm.controls['currentHolidayHoursProRata'].setValue(this.currentHolidayHoursProRata);
  }

  private updateEmployee(): void {
    this.employee.nickName = this.contentForm.value['nickName'];
    this.employee.firstName = this.contentForm.value['firstName'];
    this.employee.lastName = this.contentForm.value['lastName'];
    this.employee.email = this.contentForm.value['email'];
    this.employee.contractDate = this.contentForm.value['contractDate'];
    this.employee.endDate = this.contentForm.value['endDate'];
    this.employee.contractHours = this.contentForm.value['contractHours'];
    this.employee.holidayHoursPreviousYear = this.contentForm.value['holidayHoursPreviousYear'];
    this.employee.holidayHoursCurrentYear = this.contentForm.value['holidayHoursCurrentYear'];
    this.employee.approvedHolidayHours = this.contentForm.value['approvedHolidayHours'];
    this.employee.submittedHolidayHours = this.contentForm.value['submittedHolidayHours'];
    this.employee.roles = this.getUserRoles();
  }

  private getUserRoles(): Array<string> {
    const roles: Array<string> = [];
    if (this.contentForm.controls['roles'].get('administrator').value) {
      roles.push(UserRole.ADMINISTRATOR);
    }
    if (this.contentForm.controls['roles'].get('accounting').value) {
      roles.push(UserRole.ACCOUNTING);
    }
    if (this.contentForm.controls['roles'].get('contentManager').value) {
      roles.push(UserRole.CONTENT_MANAGER);
    }
    if (this.contentForm.controls['roles'].get('employee').value) {
      roles.push(UserRole.EMPLOYEE);
    }
    return roles;
  }
}
