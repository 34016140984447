<div id="login">
  <mat-card appearance="outlined">
    <h1 class="mat-mdc-dialog-title" translate>login.title</h1>
    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div class="mat-mdc-dialog-content">
        <mat-form-field>
          <input
            id="username"
            type="text"
            matInput
            placeholder="{{ 'login.username' | translate }}"
            ngModel
            name="username"
            required
            #emailInput="ngModel"
            trim="blur"
          />
          <mat-error *ngIf="emailInput.hasError('required')" translate>form.field.required</mat-error>
          <mat-error *ngIf="!emailInput.hasError('required')" translate>login.username.invalid</mat-error>
        </mat-form-field>
        <mat-form-field hintLabel="{{ 'login.password.length' | translate }}">
          <input
            type="password"
            matInput
            placeholder="{{ 'login.password' | translate }}"
            ngModel
            name="password"
            required
            minlength="6"
            #pwInput="ngModel"
            trim="blur"
            [type]="hide ? 'password' : 'text'"
          />
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }} </mat-icon>
          <mat-hint align="end">{{ pwInput.value?.length }} / 6</mat-hint>
          <mat-error translate>login.password.length</mat-error>
        </mat-form-field>
      </div>
      <div class="mat-mdc-dialog-actions">
        <button type="button" (click)="forgot()" mat-raised-button>{{'login.forgot.password' | translate}}</button>
        <button type="submit" mat-raised-button color="primary">{{'login.action' | translate}}</button>
      </div>
    </form>
  </mat-card>
</div>
