<section mat-dialog-container id="generated-dialog">
  <h1 mat-dialog-title>Nieuw gegenereerd wachtwoord</h1>
  <div mat-dialog-content>
    <mat-form-field style="min-width: 500px; font-size: 72px">
      <input matInput [(ngModel)]="data.generated" name="generated" readonly style="text-align: center" #generated />
    </mat-form-field>
    <div style="display: flex; flex-direction: column; align-items: center; gap: 1em">
      <button
        mat-raised-button
        color="primary"
        [matBadge]="copied ? undefined : 1"
        matBadgePosition="before"
        matBadgeColor="accent"
        (click)="copy(generated)"
        [disabled]="copied"
      >
        Kopieren naar klembord
      </button>
      <button mat-raised-button (click)="email()">E-mail naar medewerker</button>
      <button type="submit" mat-raised-button color="primary" (click)="ok()" [disabled]="!copied">Gereed</button>
    </div>
  </div>
</section>
