import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'src/app/shared/models';
import { CustomMenuItemsService, FeedbackService } from 'src/app/shared/services';

@Component({
  selector: 'app-custom-menu-item-detail',
  templateUrl: './custom-menu-item-detail.component.html',
  styleUrls: ['../../../admin/general.component.scss'],
})
export class CustomMenuItemDetailComponent implements OnInit {
  private readonly url_pattern = /^https?:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}(\/\S*)?$/;
  contentForm: UntypedFormGroup;
  id: number;
  menuItem: MenuItem;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private feedback: FeedbackService,
    public menuItemService: CustomMenuItemsService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.fetchModel();
  }

  initForm(): void {
    this.contentForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      url: ['', Validators.compose([Validators.required, Validators.pattern(this.url_pattern)])],
    });
  }

  private fetchModel(): void {
    this.route.params.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        this.menuItemService.get(this.id).subscribe((menuItem) => {
          this.menuItem = menuItem;
          this.load();
        });
      }
    });
  }

  private load(): void {
    this.contentForm.get('name').setValue(this.menuItem.name);
    this.contentForm.get('url').setValue(this.menuItem.url);
  }

  private getMenuItem(form: NgForm): MenuItem {
    return new MenuItem(form.value);
  }

  submit(form: NgForm): void {
    const menuItem = this.getMenuItem(form);
    menuItem.id = this.id;
    this.menuItemService.createOrUpdate(menuItem).subscribe({
      next: () => {
        this.feedback.openSuccessToast('Het nieuwe menu item is toegevoegd');
        this.router.navigate(['content', 'custom-menu-item-list']);
      },
      error: (err) => {
        this.feedback.openErrorToast(err);
      },
    });
  }

  cancel(): void {
    this.feedback.openNeutralToast();
    this.router.navigate(['content', 'custom-menu-item-list']);
  }
}
