import { Model } from './model';
import { MenuItemTransferObject as MenuItemDTO } from 'generated-sources';

export class MenuItem extends Model {
  name: string;
  url: string;

  constructor(dto: MenuItemDTO) {
    super();
    this.id = dto.id;
    this.name = dto.name;
    this.url = dto.url;
    this.deletable = true;
  }

  public static copy(orig: MenuItem): MenuItem {
    return new MenuItem({
      id: orig.id,
      name: orig.name,
      url: orig.url,
    });
  }

  public toString(): string {
    return this.name;
  }
}
