<ng-container role="group" [formGroup]="parts">
  <input
    formControlName="hourPart"
    #hourInput
    type="number"
    min="0"
    max="24"
    step="1"
    matInput
    [appMatInputHourified]="value"
    class="saved-input input-hour"
    autocomplete="off"
  /><span>:</span
  ><input
    formControlName="minutePart"
    #minuteInput
    type="number"
    min="0"
    max="59"
    step="15"
    matInput
    [appMatInputMinutyfied]="value"
    class="saved-input input-minute"
    autocomplete="off"
  />
</ng-container>
