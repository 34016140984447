import { Injectable } from '@angular/core';
import { Employee } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private loggedInUser: Employee;

  get employeeRoles(): string[] {
    return this.getLoggedInUser().roles;
  }

  hasRoles(roles: string[]): boolean {
    let result = 0;
    const userRoles = this.employeeRoles;
    for (const role of roles) {
      result = userRoles.indexOf(role) !== -1 ? result + 1 : result;
    }

    return !!result;
  }

  isAdministrator(): boolean {
    return this.hasRoles(['ROLE_ADMINISTRATOR']);
  }

  isContentmanager(): boolean {
    return this.hasRoles(['ROLE_CONTENT_MANAGER']);
  }

  isAccounting(): boolean {
    return this.hasRoles(['ROLE_ACCOUNTING']);
  }

  isEmployee(): boolean {
    return this.hasRoles(['ROLE_EMPLOYEE']);
  }

  setLoggedInUser(employee: Employee): void {
    this.loggedInUser = employee;
  }

  getLoggedInUser(): Employee {
    return this.loggedInUser;
  }
}
