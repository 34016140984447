import { Routes } from '@angular/router';
import { adminRoutes } from './admin/admin.routes';
import { LoginComponent } from './core/login/login/login.component';
import { employeeRoutes } from './employee/employee.routes';
import { ForgotPasswordComponent } from './employee/forgot-password/forgot-password.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { PageLayoutComponent } from './layout/page-layout/page-layout.component';
import { AuthGuardService as AuthGuard } from './shared/guards/auth-guard.service';
import { UserRole } from './shared/enums/UserRole';
import { contentRoutes } from './content/content.routes';
import { RoleGuard } from './shared/guards/role.guard';
import { ProfileComponent } from './employee/profile/profile.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: PageLayoutComponent,
    children: [
      {
        path: 'forgot',
        component: ForgotPasswordComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'profiel',
        component: ProfileComponent,
        data: {
          roleNeeded: [
            UserRole.EMPLOYEE.valueOf(),
            UserRole.ADMINISTRATOR.valueOf(),
            UserRole.ACCOUNTING.valueOf(),
            UserRole.CONTENT_MANAGER.valueOf(),
          ],
          icon: 'face',
          menuTranslateKey: 'menu.profile',
        },
      },
      {
        path: 'medewerker',
        children: employeeRoutes,
        canActivate: [RoleGuard],
        data: {
          roleNeeded: [UserRole.EMPLOYEE.valueOf()],
        },
      },
      {
        path: 'admin',
        children: adminRoutes,
        canActivate: [RoleGuard],
        data: {
          roleNeeded: [UserRole.ACCOUNTING.valueOf(), UserRole.ADMINISTRATOR.valueOf()],
        },
      },
      {
        path: 'content',
        children: contentRoutes,
        canActivate: [RoleGuard],
        data: {
          roleNeeded: [UserRole.CONTENT_MANAGER.valueOf()],
        },
      },
    ],
  },
];
