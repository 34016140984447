import { Moment } from 'moment';

export abstract class Model {
  public id?: number;
  public deletable: boolean;

  public static formatDate(m: Moment): string {
    return m?.format('YYYY-MM-DD');
  }

  public equals(a: Model): boolean {
    return a && a.id === this.id;
  }
}
