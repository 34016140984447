<div id="new-password">
  <h2 translate>Nieuw wachtwoord instellen</h2>

  <div class="header-wrapper">
    <mat-card appearance="outlined" style="width: 100%" *ngIf="!sent">
      <form #f="ngForm" [formGroup]="contentForm">
        <mat-form-field class="mat-mdc-form-field">
          <input
            matInput
            placeholder="{{ 'Huidig wachtwoord' | translate }}"
            [type]="hideCurrentPassword ? 'password' : 'text'"
            formControlName="currentPassword"
            name="currentPassword"
            autocomplete="new-password"
            required
            tabindex="1"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hideCurrentPassword = !hideCurrentPassword"
            [attr.aria-label]="'Wachtwoord verbergen'"
            [attr.aria-pressed]="hideCurrentPassword"
            tabindex="-1"
          >
            <mat-icon>{{ hideCurrentPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
        <br />
        <mat-form-field class="mat-mdc-form-field">
          <input
            matInput
            placeholder="{{ 'Nieuw wachtwoord' | translate }}"
            [type]="hideNewPassword ? 'password' : 'text'"
            formControlName="newPassword"
            name="newPassword"
            autocomplete="new-password"
            required
            tabindex="2"
          />
          <mat-error translate
            >Het wachtwoord moet minimaal 8 karakters lang zijn en 2 hoofdletters, 1 getal en 1 speciaal teken
            bevatten.</mat-error
          >
          <button
            mat-icon-button
            matSuffix
            (click)="hideNewPassword = !hideNewPassword"
            [attr.aria-label]="'Wachtwoord verbergen'"
            [attr.aria-pressed]="hideNewPassword"
            tabindex="-1"
          >
            <mat-icon>{{ hideNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
        <br />
        <mat-form-field class="mat-mdc-form-field">
          <input
            matInput
            placeholder="{{ 'Nieuw wachtwoord' | translate }}"
            [type]="hideRepeated ? 'password' : 'text'"
            formControlName="repeated"
            name="repeated"
            autocomplete="new-password"
            required
            tabindex="3"
          />
          <mat-error *ngIf="contentForm.errors?.equalValue">De nieuwe wachtwoorden komen niet overeen.</mat-error>
          <button
            mat-icon-button
            matSuffix
            (click)="hideRepeated = !hideRepeated"
            [attr.aria-label]="'Wachtwoord verbergen'"
            [attr.aria-pressed]="hideRepeated"
            tabindex="-1"
          >
            <mat-icon>{{ hideRepeated ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
        <br />
        <br />
        <button
          mat-raised-button
          (click)="setNewPassword()"
          color="primary"
          tabindex="4"
          [disabled]="contentForm.invalid"
        >
          <mat-icon>done</mat-icon>
          {{ 'Opslaan' | translate }}</button
        >&nbsp;
        <a href="/">
          <button mat-raised-button tabindex="5" (click)="cancel()">
            <mat-icon>close</mat-icon>
            {{ 'Annuleren' | translate }}
          </button></a
        >
      </form>
    </mat-card>

    <mat-card appearance="outlined" *ngIf="sent" translate>
      <div mat-dialog-actions style="display: flex; flex-direction: column; align-items: center; gap: 10px">
        Uw wachtwoord is gewijzigd.
        <br />
        <br />
        <a href="/">
          <button mat-raised-button color="primary" (click)="login();">{{ 'Opnieuw inloggen' | translate }}</button>
        </a>
      </div>
    </mat-card>
  </div>
</div>
