import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-restore-dialog',
  templateUrl: './restore-dialog.component.html',
  styleUrls: ['./restore-dialog.component.scss'],
})
export class RestoreDialogComponent implements OnInit {
  info: string;

  constructor(
    public dialogRef: MatDialogRef<RestoreDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RestoreDialogModel,
  ) {
    // Update view with given values
    this.info = data.info;
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

export class RestoreDialogModel {
  constructor(public info: string) {}
}
