import { Injectable } from '@angular/core';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '../components/dialogs/confirm-dialog/confirm-dialog.component';
import { SubmitHoursDialogComponent } from '../components/dialogs/submit-hours-dialog/submit-hours-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  RestoreDialogComponent,
  RestoreDialogModel,
} from '../components/dialogs/restore-dialog/restore-dialog.component';
import { HolidayHoursDialogComponent } from '../components/dialogs/holiday-hours-dialog/holiday-hours-dialog.component';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {}

  public openSuccessToast(message: string): void {
    this.snackBar.open(message, '', {
      duration: 3000,
      panelClass: ['success-snackbar'],
    });
  }

  public openNeutralToast(): void {
    this.snackBar.open('Er is niets gewijzigd', '', {
      duration: 3000,
      panelClass: ['info-snackbar'],
    });
  }

  public openErrorToast(response: unknown, defaultMessage?: string): void {
    let message = defaultMessage || 'Neem contact op met het technische team';
    if (this.hasErrorMessage(response)) {
      message = response.error.message;
    } else if (typeof response === 'string') {
      message = response;
    }

    this.snackBar.open(this.translate.instant(message), '', {
      duration: 5000,
      panelClass: ['error-snackbar'],
    });

    console.error(response);
  }

  getArchiveConfirmation(message: string): Observable<unknown> {
    const dialogData = new ConfirmDialogModel(message, true);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '33%',
      data: dialogData,
    });
    return dialogRef.afterClosed();
  }

  getDeleteConfirmation(message: string): Observable<unknown> {
    const dialogData = new ConfirmDialogModel(message, false);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '33%',
      data: dialogData,
    });
    return dialogRef.afterClosed();
  }

  getRestoreConfirmation(message: string): Observable<unknown> {
    const dialogData = new RestoreDialogModel(message);
    const dialogRef = this.dialog.open(RestoreDialogComponent, {
      width: '33%',
      data: dialogData,
    });
    return dialogRef.afterClosed();
  }

  submitConfirmation(): Observable<boolean> {
    const dialogRef = this.dialog.open(SubmitHoursDialogComponent, {
      width: '33%',
    });
    return dialogRef.afterClosed();
  }

  getHolidayHoursInformation(id: number): Observable<unknown> {
    const dialogRef = this.dialog.open(HolidayHoursDialogComponent, {
      width: '33%',
      data: { id },
    });
    return dialogRef.afterClosed();
  }

  private hasErrorMessage(obj: unknown): obj is { error: { message: string } } {
    return (
      obj &&
      typeof obj === 'object' &&
      'error' in obj &&
      obj['error'] &&
      typeof obj['error'] === 'object' &&
      'message' in obj['error'] &&
      obj['error']['message'] &&
      typeof obj['error']['message'] === 'string'
    );
  }
}
