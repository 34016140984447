import moment from 'moment';

export class Logger {
  private clazz: string;

  constructor(clazz: string) {
    this.clazz = clazz;
  }

  public log(...a: unknown[]): void {
    const dev = location.hostname.indexOf('localhost') > -1;
    if (dev) {
      a.splice(0, 0, '[' + this.clazz + ' @ ' + moment().format('HH:mm:ss') + ']');
      console.log(...a);
    }
  }
}

const devtools = function () {
  // No implementation required
};
devtools.toString = function () {
  this.opened = true;
  return '';
};
