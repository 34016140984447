<div id="week-hours">
  <table class="mat-table mat-mdc-table" aria-label="Elements" id="uren-table">
    <caption>
      Week uren
    </caption>
    <thead>
      <tr id="second-header">
        <th scope="col" class="mat-header-cell border-bottom border-right table-width-wider">
          <p class="project-cell">Project</p>
        </th>
        <th
          scope="col"
          class="mat-header-cell border-bottom table-width center-content"
          *ngFor="let day of weekdays"
          [ngClass]="{ hasEvent: day.events }"
        >
          <div class="dddd">{{ day.date | amDateFormat: 'dddd' }}</div>
          <div class="ddd">{{ day.date | amDateFormat: 'ddd' }}</div>
          <div class="date">{{ day.date | amDateFormat: 'DD MMM' }}</div>
          <div *ngIf="day.events" class="event">{{ day.events.name }}</div>
        </th>

        <th scope="col" class="mat-header-cell border-bottom center-content">
          <p translate>Totaal</p>
        </th>
      </tr>
      <tr id="third-header">
        <th scope="col" class="mat-header-cell border-bottom border-right table-width-wider">
          <p class="project-cell" translate>Thuis gewerkt</p>
        </th>
        <th
          scope="col"
          class="mat-header-cell border-bottom table-width center-content"
          *ngFor="let day of weekdays"
          [ngClass]="{ hasEvent: day.events }"
        >
          <mat-checkbox
            [checked]="initValueOfWorkAtHome(day)"
            (change)="onWorkFromHomeSelected(day, $event)"
            [disabled]="disableWorkAtHome(day)"
          >
          </mat-checkbox>
        </th>

        <th scope="col" class="mat-header-cell border-bottom"></th>
      </tr>
    </thead>
    <tbody *ngFor="let row of rows; let rowIndex = index" data-cy="project-row">
      <tr>
        <td class="table-width-wider border-right">
          <mat-form-field [floatLabel]="'auto'" [appearance]="'fill'" class="mat-mdc-form-field">
            <mat-select
              [value]="row.assignment"
              [placeholder]="'Project'"
              [compareWith]="compareAssignment"
              required
              matSelect
              (selectionChange)="onProjectSelected(row, $event)"
              [disabled]="disableProjectSelect(row)"
            >
              <mat-optgroup *ngFor="let customer of customers" [label]="customer.name">
                <mat-option
                  *ngFor="let assignment of customer.assignments | sort: 'name'"
                  [value]="assignment"
                  matOption
                  [disabled]="isAssignmentSelected(assignment)"
                  [style.display]="isAssignmentSelected(assignment) ? 'none' : 'block'"
                >
                  {{assignment.name}}
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </td>
        <td
          class="table-width"
          *ngFor="let holder of row.days; let first = first; let index = index"
          [ngClass]="{ hasEvent: weekdays[index].events }"
        >
          <mat-form-field class="hour-input-form-field mat-mdc-form-field" floatLabel="never">
            <mat-icon class="ingediend-icon" *ngIf="holder.submitted" label="Ingediend"> add_circle_outline </mat-icon>
            <mat-icon class="goedgekeurd-icon" *ngIf="holder.approved" label="Goedgekeurd">
              check_circle_outline
            </mat-icon>
            <app-hours-input
              [(ngModel)]="holder.effort.hours"
              ngDefaultControl
              #input
              [disabled]="disableDailyEffortInput(row, holder)"
              [placeholder]="determineDailyEffortInputPlaceholder(input)"
              (change)="onEffort(row, holder, weekdays[index], input)"
              (focus)="focus($event)"
              [ngStyle]="setStyle(holder.effort)"
              [title]="setTitle(holder.effort)"
            ></app-hours-input>
          </mat-form-field>
        </td>
        <td class="table-width center-content">
          <span class="sum">{{ row.total() | DecimalToHours }}</span>
        </td>
      </tr>
    </tbody>
    <tbody class="table-width-wider" data-cy="project-totals">
      <tr class="border-top">
        <td><span class="project-cell" translate>Totaal</span></td>
        <td class="center-content" *ngFor="let day of weekdays">
          <span class="sum">{{ dayTotal(day) | DecimalToHours }} </span>
        </td>
        <td class="center-content">
          <span class="sum">{{ total() | DecimalToHours }}</span>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="9" class="centerButton"></td>
      </tr>
    </tfoot>
  </table>
</div>
