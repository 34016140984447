<div id="set-password">
  <h2 translate>Wachtwoord instellen</h2>

  <div class="header-wrapper">
    <mat-card appearance="outlined" style="width: 100%" *ngIf="!sent">
      <form #f="ngForm">
        <mat-form-field class="mat-mdc-form-field">
          <input
            matInput
            placeholder="{{ 'Wachtwoord' | translate }}"
            [type]="hide ? 'password' : 'text'"
            [(ngModel)]="password"
            name="password"
            autocomplete="new-password"
            required
            tabindex="1"
            [pattern]="passwordPattern"
          />
          <mat-error translate
            >Het wachtwoord moet minimaal 8 karakters lang zijn en 2 hoofdletters, 1 getal en 1 speciaal teken
            bevatten.</mat-error
          >
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Wachtwoord verbergen'"
            [attr.aria-pressed]="hide"
            tabindex="-1"
          >
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
        <br />
        <mat-form-field class="mat-mdc-form-field">
          <input
            matInput
            placeholder="{{ 'Wachtwoord' | translate }}"
            [type]="hide ? 'password' : 'text'"
            [(ngModel)]="repeated"
            name="repeated"
            autocomplete="new-password"
            required
            tabindex="2"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Wachtwoord verbergen'"
            [attr.aria-pressed]="hide"
            tabindex="-1"
          >
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
        <br />
        <br />
        <button
          mat-raised-button
          (click)="setPassword()"
          [disabled]="f.invalid || password !== repeated"
          color="primary"
          tabindex="3"
        >
          <mat-icon>done</mat-icon>
          {{ 'Opslaan' | translate }}</button
        >&nbsp;
        <a href="/">
          <button mat-raised-button tabindex="4" (click)="cancel()">
            <mat-icon>close</mat-icon>
            {{ 'Annuleren' | translate }}
          </button></a
        >
      </form>
    </mat-card>

    <mat-card appearance="outlined" *ngIf="sent" translate>
      <div mat-dialog-actions style="display: flex; flex-direction: column; align-items: center; gap: 10px">
        Uw wachtwoord is gewijzigd. <br />
        <br />
        <a href="/">
          <button mat-raised-button color="primary">{{ 'Inloggen' | translate }}</button>
        </a>
      </div>
    </mat-card>
  </div>
</div>
