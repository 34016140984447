import { ValidationErrors, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export class UraValidators {
  static rolesRequired(formGroup: UntypedFormGroup): ValidationErrors {
    if (
      !formGroup.get('administrator').value &&
      !formGroup.get('accounting').value &&
      !formGroup.get('employee').value &&
      !formGroup.get('contentManager').value
    ) {
      return { rolesRequired: true };
    }
  }

  static dateRangeValidator(formGroup: UntypedFormGroup): ValidationErrors {
    if (!formGroup.get('endDate').value) {
      return;
    }
    return moment(formGroup.get('startDate').value).isSameOrBefore(moment(formGroup.get('endDate').value))
      ? null
      : { invalidDateRange: true };
  }

  /** this control value must be equal to given control's value */
  static equalValueValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: unknown } => {
      const control = group.controls[controlName];
      const matchingControl = group.controls[matchingControlName];

      if (control.errors || (matchingControl.errors && !matchingControl.errors.mustMatch)) {
        // return if another validator has already found an error on the matchingControl or control
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ equalValue: true });
        return { equalValue: true };
      }
      return null;
    };
  }
}
