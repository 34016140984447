import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UraOverviewComponent } from './ura-overview/ura-overview.component';
import { MaterialModule } from '../../material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UraOverviewComponent],
  exports: [UraOverviewComponent],
  imports: [CommonModule, MaterialModule, TranslateModule],
})
export class UraOverviewModule {}
