import { Component, EventEmitter, Output } from '@angular/core';
import { LoginService, UserService } from 'src/app/shared/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() sidenavToggle = new EventEmitter<void>();

  constructor(
    private loginService: LoginService,
    private userService: UserService,
  ) {}

  get nickName(): string {
    return this.userService.getLoggedInUser().nickName;
  }

  loggedIn(): boolean {
    return this.loginService.isLoggedIn();
  }

  onToggleSidenav(): void {
    this.sidenavToggle.emit();
  }

  logout(): void {
    this.loginService.logOut();
  }
}
