<div id="employee-edit">
  <h2>Medewerker bewerken</h2>
  <div class="header-wrapper">
    <mat-accordion multi="true">
      <form #editEmployeeForm="ngForm" (ngSubmit)="onSubmit()" [formGroup]="contentForm">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> Persoonlijke gegevens </mat-panel-title>
            <mat-panel-description>
              <mat-icon color="primary">face</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label>{{"Gebruikersnaam" | translate}}</mat-label>
            <input type="text" matInput formControlName="userName" #userName />
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{"Roepnaam" | translate}}</mat-label>
            <input
              type="text"
              matInput
              formControlName="nickName"
              required
              (change)="dirty = true"
              (beforeinput)="dirty = true"
              trim="blur"
            />
            <mat-error *ngIf="contentForm.controls.nickName.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.nickName.errors?.maxlength"
              >Veld mag maximaal 40 lang zijn
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{"Voornaam" | translate}}</mat-label>
            <input
              type="text"
              matInput
              formControlName="firstName"
              required
              (change)="dirty = true"
              (beforeinput)="dirty = true"
              trim="blur"
            />
            <mat-error *ngIf="contentForm.controls.firstName.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.firstName.errors?.maxlength"
              >Veld mag maximaal 50 lang zijn
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{"Achternaam" | translate}}</mat-label>
            <input
              type="text"
              matInput
              formControlName="lastName"
              required
              (change)="dirty = true"
              (beforeinput)="dirty = true"
              trim="blur"
            />
            <mat-error *ngIf="contentForm.controls.lastName.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.lastName.errors?.maxlength"
              >Veld mag maximaal 40 lang zijn
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{"E-mailadres" | translate}}</mat-label>
            <input
              type="email"
              matInput
              formControlName="email"
              required
              (change)="dirty = true"
              (beforeinput)="dirty = true"
              trim="blur"
            />
            <mat-error *ngIf="contentForm.controls.email.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.email.errors?.pattern">Ongeldig e-mailadres</mat-error>
          </mat-form-field>
          <br />
          <div class="roles" formGroupName="roles">
            <mat-label class="roles_label">Gebruikersrollen *</mat-label>
            <mat-checkbox id="cbAdministrator" formControlName="administrator" (change)="dirty = true">
              Administrator
            </mat-checkbox>
            <mat-checkbox id="cbAccounting" formControlName="accounting" (change)="dirty = true">
              Accounting
            </mat-checkbox>
            <mat-checkbox id="cbContentManager" formControlName="contentManager" (change)="dirty = true">
              Inhoudsbeheerder
            </mat-checkbox>
            <mat-checkbox id="cbEmployee" formControlName="employee" (change)="dirty = true"> Medewerker </mat-checkbox>
          </div>
          <mat-error class="roles_error" *ngIf="contentForm.controls.roles.errors?.rolesRequired"
            >Veld moet worden ingevuld
          </mat-error>
        </mat-expansion-panel>
        <br />
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Contractgegevens </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label>{{"Ingangsdatum contract" | translate}}</mat-label>
            <input
              matInput
              [matDatepicker]="contractDate"
              formControlName="contractDate"
              name="contractDate"
              readonly
              required
              (click)="contractDate.open()"
            />
            <mat-datepicker-toggle matSuffix [for]="contractDate"></mat-datepicker-toggle>
            <mat-datepicker #contractDate></mat-datepicker>
            <mat-error *ngIf="contentForm.controls.contractDate.errors?.required">Veld moet worden ingevuld</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{"Beëindigingsdatum contract" | translate}}</mat-label>
            <input
              matInput
              [matDatepicker]="endDate"
              formControlName="endDate"
              name="endDate"
              readonly
              (click)="endDate.open()"
            />
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker-toggle matSuffix (click)="clearEndDate()" *ngIf="!!contentForm.controls.endDate.value">
              <mat-icon matDatepickerToggleIcon>clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
            <mat-error *ngIf="contentForm.controls.endDate.errors?.required">Veld moet worden ingevuld</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{"Contract-uren" | translate}}</mat-label>
            <input
              class="numberHours"
              required
              type="number"
              matInput
              formControlName="contractHours"
              (change)="dirty = true"
              (beforeinput)="acceptNumericOnly($event)"
              min="1"
            />
            <mat-error *ngIf="contentForm.controls.contractHours.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.contractHours.errors?.pattern">Ongeldig aantal uren</mat-error>
            <mat-error *ngIf="contentForm.controls.contractHours.errors?.max"
              >Mag maximaal {{ contentForm.controls.contractHours.errors?.max.max }} zijn
            </mat-error>
          </mat-form-field>
        </mat-expansion-panel>
        <br />
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title translate>hours.holidayHours</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label translate>hours.holidayHoursCurrentYear</mat-label>
            <input
              class="numberHours"
              required
              type="number"
              matInput
              formControlName="holidayHoursCurrentYear"
              (change)="dirty = true"
              (beforeinput)="acceptNumericOnly($event)"
            />
            <mat-error *ngIf="contentForm.controls.holidayHoursCurrentYear.errors?.required"
              >Veld moet worden ingevuld
            </mat-error>
            <mat-error *ngIf="contentForm.controls.holidayHoursCurrentYear.errors?.pattern"
              >Ongeldig aantal uren (max. 3 cijfers en 2 decimalen)
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label translate [translateParams]="{year:employee.holidayYear - 1}"
              >hours.holidayHoursPreviousYear</mat-label
            >
            <input
              class="numberHours"
              required
              type="number"
              matInput
              formControlName="holidayHoursPreviousYear"
              (change)="dirty = true"
              (beforeinput)="acceptNumericOnly($event)"
            />
            <mat-error *ngIf="contentForm.controls.holidayHoursPreviousYear.errors?.required"
              >Veld moet worden ingevuld
            </mat-error>
            <mat-error *ngIf="contentForm.controls.holidayHoursPreviousYear.errors?.pattern"
              >Ongeldig aantal uren (max. 3 cijfers en 2 decimalen)
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label *ngIf="isPastEmployment()" translate>hours.accrualMonthOutOfService</mat-label>
            <mat-label *ngIf="isPastHolidayYear()" translate [translateParams]="{year: holidayYear() - 1}"
              >hours.holidayHoursEndOfYear</mat-label
            >
            <mat-label *ngIf="isCurrentHolidayYear()" translate>hours.holidayHoursCurrentYearProRata</mat-label>
            <input matInput type="string" class="numberHours" formControlName="holidayHoursCurrentYearProRata" />
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label *ngIf="isPastHolidayYear()" translate [translateParams]="{year: holidayYear()}"
              >hours.holidayHoursApprovedEndOfYear</mat-label
            >
            <mat-label *ngIf="!isPastHolidayYear()" translate>hours.approvedHolidayHours</mat-label>
            <input
              class="numberHours"
              required
              type="number"
              matInput
              formControlName="approvedHolidayHours"
              (change)="dirty = true"
              (beforeinput)="acceptNumericOnly($event)"
            />
            <mat-error *ngIf="contentForm.controls.approvedHolidayHours.errors?.required"
              >Veld moet worden ingevuld
            </mat-error>
            <mat-error *ngIf="contentForm.controls.approvedHolidayHours.errors?.pattern"
              >Ongeldig aantal uren (max. 3 cijfers en 2 decimalen)
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label *ngIf="isPastEmployment()" translate>hours.balanceHolidayTermination</mat-label>
            <mat-label *ngIf="isPastHolidayYear()" translate [translateParams]="{year: holidayYear()}"
              >hours.holidayHoursPreviousYear</mat-label
            >
            <mat-label *ngIf="isCurrentHolidayYear()" translate>hours.holidayBalanceRemainingProRata</mat-label>
            <input
              [ngStyle]="{'color': currentHolidayHoursProRata < 0 ? 'red' : null }"
              type="string"
              class="numberHours"
              matInput
              formControlName="currentHolidayHoursProRata"
            />
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label translate>hours.submittedHolidayHours</mat-label>
            <input
              class="numberHours"
              required
              type="number"
              matInput
              formControlName="submittedHolidayHours"
              (change)="dirty = true"
              (beforeinput)="acceptNumericOnly($event)"
            />
            <mat-error *ngIf="contentForm.controls.submittedHolidayHours.errors?.required"
              >Veld moet worden ingevuld
            </mat-error>
            <mat-error *ngIf="contentForm.controls.submittedHolidayHours.errors?.pattern"
              >Ongeldig aantal uren (max. 3 cijfers en 2 decimalen)
            </mat-error>
          </mat-form-field>
          <button
            id="rollover-holiday-year"
            type="button"
            mat-stroked-button
            (click)="rolloverHoliday()"
            *ngIf="isPastHolidayYear()"
          >
            Verwerk nieuw jaar
          </button>
        </mat-expansion-panel>
        <div class="actions">
          <button type="reset" mat-raised-button (click)="cancel()">
            <mat-icon>close</mat-icon>
            Annuleren
          </button>
          <button
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!dirty || editEmployeeForm.invalid"
            (click)="onSubmit()"
          >
            <mat-icon>done</mat-icon>
            Opslaan
          </button>
        </div>
      </form>
    </mat-accordion>
  </div>
</div>
