import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { DialogsModule } from '../../shared/components/dialogs/dialogs.module';
import { MaterialModule } from 'src/app/shared/material.module';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, DialogsModule, MaterialModule, NgxTrimDirectiveModule, TranslateModule],
  exports: [LoginComponent],
})
export class LoginModule {}
