<h2 translate>Wachtwoord vergeten</h2>

<div class="header-wrapper">
  <mat-vertical-stepper [linear]="isLinear" #stepper style="width: 100%">
    <mat-step [editable]="editable">
      <form>
        <ng-template matStepLabel>{{ 'Procedure' | translate }}</ng-template>
        <div translate>
          <br />
          Een wachtwoord (in computerjargon spreekt men ook over paswoord of password) is een afgesproken woord of zin
          waaraan belanghebbenden elkaar herkennen. Het wachtwoord is geheim voor buitenstaanders.<br />
          <br />
          Een wachtwoord wordt zo ook gebruikt om toegang te verschaffen tot bijvoorbeeld gebouwen of computers die met
          digitale middelen zijn beveiligd. Het wachtwoord is alleen bekend aan degene die toestemming heeft om het te
          gebruiken.<br />
          <br />
          In de ICT is het wachtwoord normaliter gekoppeld aan een userName die identificeert wie er toegang mag
          hebben.<br />
          <br />
          Voert men een wachtwoord op de computer in, dan blijft het op het scherm haast altijd onzichtbaar. Zodoende
          kan het wachtwoord niet worden afgelezen door iemand die over de schouder van de gebruiker meekijkt, en ook
          niet als via een andere computer (voor hulp op afstand) wordt meegelezen. Nadeel hiervan is dat de gebruiker
          geen directe controle op typefouten heeft. Wordt een wachtwoord voor de eerste keer ingesteld, dan moet het
          haast altijd twee keer ingevoerd worden (dit wordt vaak “bevestigen” genoemd) om onopgemerkte typefouten te
          vermijden.<br />
          <br />
          Door het achterhalen van een wachtwoord kan een derde persoon onterecht gebruikmaken van andermans informatie.
          Het wachtwoord kan achterhaald worden door het af te kijken, door een wachtwoordenbestand te ontcijferen, of
          door verschillende eenvoudige wachtwoorden te proberen. Om die reden wordt in de meeste professionele
          omgevingen een zogenaamd complex wachtwoord vereist dat regelmatig door de eigenaar ervan moet worden
          gewijzigd. Een andere beveiliging, die onder andere bij Wikipedia wordt toegepast, is dat een account wordt
          afgesloten als meerdere keren een verkeerd wachtwoord wordt ingevoerd. Pas na enige tijd, bijvoorbeeld een
          uur, is inloggen weer mogelijk. Hierdoor is het onmogelijk in korte tijd een groot aantal wachtwoorden te
          proberen.<br />
          <br />
          Een variant op het wachtwoord is het sjibbolet waarmee men, bijvoorbeeld in oorlogstijd, vriend van vijand
          onderscheidt. Een voorbeeld is Scilt ende vriend waarmee volgens de legende franskiljons tijdens de Brugse
          metten ontmaskerd zouden zijn,[3] of Scheveningen dat tijdens de Duitse aanval op Nederland in 1940 eenzelfde
          functie had.[4]<br />
          <br />
        </div>
        <div>
          <button mat-raised-button color="primary" matStepperNext>{{ 'Volgende stap' | translate }}</button>
        </div>
      </form>
    </mat-step>
    <mat-step [editable]="editable">
      <form #email="ngForm">
        <ng-template matStepLabel>{{ 'Emailadres invullen' | translate }}</ng-template>
        <mat-form-field class="mat-mdc-form-field">
          <input
            type="email"
            matInput
            [(ngModel)]="address"
            name="account"
            placeholder="{{ 'E-mailadres' | translate }}"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            trim="blur"
          />
          <mat-error>{{ 'Ongeldig e-mailadres' | translate }}</mat-error>
        </mat-form-field>
        <div>
          <button mat-raised-button matStepperPrevious>{{ 'Terug' | translate }}</button>
          &nbsp;
          <button
            mat-raised-button
            color="primary"
            (click)="resetPassword(address)"
            [disabled]="email.invalid"
            matStepperNext
          >
            {{ 'Wachtwoord herstellen' | translate }}
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [editable]="editable">
      <ng-template matStepLabel>{{ 'Voltooid' | translate }}</ng-template>
      <div translate>
        <br />
        Als er een account actief is behorende bij het emailadres, dan is er een email gestuurd met het verzoek tot
        herstel.<br />
        <br />
        Controleer je emailbox daarvoor.<br />
        <br />
        <a href="/"> <button mat-raised-button color="primary">{{ 'Inloggen' | translate }}</button></a>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
