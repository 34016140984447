import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UraValidators } from 'src/app/shared/helper/ura-validators';
import { EmployeesService, FeedbackService } from 'src/app/shared/services';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  private readonly passwordPattern =
    // eslint-disable-next-line sonarjs/regex-complexity,sonarjs/concise-regex
    /^(?=.{8,}$)(?=(?:.*?[A-Z]){2})(?=.*?[a-z])(?=(?:.*?\d){1})(?=.*[!"#$%&'()*+,\\\-./:;<=>?@[\]^_`{|}~]).*$/;
  contentForm: UntypedFormGroup;

  public currentPassword: string;
  public newPassword: string;
  public repeated: string;

  public sent = false;
  public hideCurrentPassword = true;
  public hideNewPassword = true;
  public hideRepeated = true;

  constructor(
    private employeesService: EmployeesService,
    private feedbackService: FeedbackService,
    private router: Router,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.contentForm = new UntypedFormGroup(
      {
        currentPassword: new UntypedFormControl('', [Validators.required]),
        newPassword: new UntypedFormControl('', [Validators.required, Validators.pattern(this.passwordPattern)]),
        repeated: new UntypedFormControl('', [Validators.required]),
      },
      { validators: UraValidators.equalValueValidator('newPassword', 'repeated') },
    );
  }

  setNewPassword(): void {
    this.employeesService
      .setNewPassword(this.contentForm.get('currentPassword')?.value, this.contentForm.get('newPassword')?.value)
      .subscribe({
        next: () => {
          this.sent = true;
        },
        error: (err: unknown) => {
          this.feedbackService.openErrorToast(err, 'Het zetten van een nieuw wachtwoord is fout gegaan');
        },
      });
  }

  cancel(): void {
    this.location.back();
  }

  login(): void {
    this.router.navigate(['/login']);
  }
}
