<h2 translate>Uren</h2>

<div class="header-wrapper">
  <div class="mat-elevation-z8 mat-mdc-table" aria-label="Elements" id="uren-table">
    <div id="first-header">
      <mat-form-field class="mat-header-cell" data-cy="weekdate-monday">
        <input
          placeholder="{{ 'Datum:' |translate }}"
          [(ngModel)]="selectedWeekValue.startOfWeek"
          (dateChange)="setDate($event.value)"
          matInput
          [matDatepickerFilter]="dateFilter"
          (click)="from.open()"
          readonly
          [matDatepicker]="$any(from)"
        />
        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
        <mat-datepicker #from></mat-datepicker>
      </mat-form-field>
      <div style="display: inline-block" data-cy="navigate-dates">
        <button mat-icon-button (click)="previousWeek()"><mat-icon>chevron_left</mat-icon></button>
        <mat-form-field style="width: 120px" data-cy="week-number">
          <mat-select
            [(ngModel)]="selectedWeekValue"
            [compareWith]="compareWeek"
            [placeholder]='"Week:" | translate'
            [panelWidth]="null"
          >
            <mat-option *ngFor="let week of weeks" [value]="week">
              Week {{ !weekSelect.panelOpen ? week.startOfWeek.week() : week.startOfWeek.week() + ': ' +
              this.weekStart(week) + ' t/m ' + this.weekEnd(week) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-icon-button (click)="nextWeek()"><mat-icon>chevron_right</mat-icon></button>
        <button mat-icon-button [disabled]="isThisWeek()" color="primary" (click)="reset()">
          <mat-icon>home</mat-icon>
        </button>
      </div>
      <button
        mat-raised-button
        color="primary"
        class="mat-header-cell border-bottom align-left mat-mdc-raised-button"
        (click)="submitEfforts()"
        [disabled]="!hasSubmittableHours"
      >
        <mat-icon>assignment_turned_in</mat-icon>

        <span translate>Uren indienen</span>
      </button>
    </div>
    <mat-divider></mat-divider>
    <app-week-hours (submittable)="onSubmittable($event)" [week]="selectedWeekValue" [submit]="submit">
    </app-week-hours>
  </div>
</div>
