<div id="hours-overview">
  <h2>Urenoverzicht</h2>
  <div class="header-wrapper">
    <mat-form-field class="mat-mdc-form-field">
      <mat-label>{{"Zoek op naam" | translate }}</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        [formControl]="filterControl"
        [matAutocomplete]="auto"
      />
      <mat-icon matSuffix>search</mat-icon>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let row of filteredOptions | async" [value]="row"> {{ row }} </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="buttonbar">
      <button
        mat-mini-fab
        class="icon-button"
        matTooltipClass="tooltip-blue"
        matTooltip="Export"
        (click)="showExportDialog()"
      >
        <mat-icon matSuffix>get_app</mat-icon>
      </button>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table class="full-width-table" [dataSource]="dataSource" matSort aria-label="Elements">
      <!-- Overzicht medewerkers -->
      <!-- Achternaam Column -->
      <ng-container matColumnDef="lastName">
        <th scope="row" mat-header-cell *matHeaderCellDef mat-sort-header>Achternaam</th>
        <td mat-cell *matCellDef="let row" style="width: 30%">{{ row.lastName }}</td>
      </ng-container>
      <!-- Voornaam Column -->
      <ng-container matColumnDef="firstName">
        <th scope="row" mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%">Voornaam</th>
        <td mat-cell *matCellDef="let row">{{ row.firstName }}</td>
      </ng-container>
      <ng-container matColumnDef="icons">
        <th scope="row" mat-header-cell *matHeaderCellDef style="width: 20%">&nbsp;</th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button color="primary" (click)="link(row)">
            <mat-icon>date_range</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="onFeedback(row, $event)" *ngIf="isPastHolidayYear(row)">
            <mat-icon>feedback</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        (click)="link(row)"
        [class]="row.lastMonthApproved ? 'approved' : 'notApproved'"
        [ngStyle]="{ 'background-color': row.lastMonthApproved ? 'approved' : 'notApproved' }"
        *matRowDef="let row; columns: displayedColumns"
        style="cursor: pointer"
      ></tr>
    </table>

    <div *ngIf="!dataSource?.filteredData.length" style="margin: 1rem">Geen resultaten.</div>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
  </div>
</div>
