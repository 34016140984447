import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomMenuItemsService } from 'src/app/shared/services';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ContentModule } from 'src/app/content/content.module';

@NgModule({
  declarations: [SidenavComponent],
  exports: [SidenavComponent],
  imports: [CommonModule, TranslateModule, MatListModule, RouterModule, MatIconModule, ContentModule],
  providers: [CustomMenuItemsService],
})
export class SidenavModule {}
