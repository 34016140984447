import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDrawerMode } from '@angular/material/sidenav';
import { UserService } from 'src/app/shared/services';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent {
  mode: MatDrawerMode = 'side';
  opened = true;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
  ) {
    breakpointObserver
      .observe([Breakpoints.WebLandscape])
      .subscribe((result) => this.reflectBreakpoints(result.breakpoints));
  }

  reflectBreakpoints(breakpoints: Record<string, unknown>): void {
    if (breakpoints[Breakpoints.WebLandscape]) {
      this.mode = 'side';
      this.opened = true;
    } else {
      this.mode = 'over';
      this.opened = false;
    }
  }

  isLoggedIn(): boolean {
    return !!this.userService.getLoggedInUser();
  }
}
