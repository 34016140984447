import { Assignment } from './assignment';
import { Model } from './model';
import { Customer as CustomerDTO } from 'generated-sources';

export class Customer extends Model {
  public name: string;
  public deletable: boolean;
  public internalTask: boolean;
  public assignments: Assignment[] = [];

  constructor(raw: CustomerDTO) {
    super();
    this.id = raw.id;
    this.name = raw.name;
    this.deletable = !!raw.deletable;
    this.internalTask = raw.internalTask;
  }

  public isInternal(): boolean {
    return this.name.trim().startsWith('XLence');
  }
}
