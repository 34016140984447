import { Model } from './model';
import moment, { Moment } from 'moment';
import { DailyEffort as DailyEffortDTO } from 'generated-sources';

export class DailyEffort extends Model {
  assignmentId: number;
  day: Moment;
  hours: number;
  hasBeenSubmitted: boolean;
  submittedByDate: Moment;
  approvedByDate: Moment;
  deletable: boolean;
  updatedBy?: string;
  workedFromHome = false;

  constructor(dto?: DailyEffortDTO) {
    super();
    if (dto) {
      this.id = dto.id;
      this.assignmentId = dto.assignmentId;
      this.day = dto.day ? moment(dto.day).hours(0).minutes(0) : null;
      this.hours = dto.hours;
      this.hasBeenSubmitted = dto.hasBeenSubmitted;
      this.submittedByDate = dto.submittedByDate ? moment(dto.submittedByDate) : null;
      this.approvedByDate = dto.approvedByDate ? moment(dto.approvedByDate) : null;
      this.deletable = !!dto.deletable;
      this.updatedBy = dto.updatedBy;
      this.workedFromHome = dto.workedFromHome;
    }
  }

  public get submitted(): boolean {
    return !!this.submittedByDate;
  }

  public get approved(): boolean {
    return !!this.approvedByDate;
  }
}
