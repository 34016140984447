import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { EmployeesService } from 'src/app/shared/services';
import { AssignmentModule } from './assignment/assignment.module';
import { CustomersModule } from './customers/customers.module';
import { EmployeesModule } from './employee/employees.module';
import { HoursOverviewModule } from './hours-overview/hours-overview.module';
import { ProjectsModule } from './projects/projects.module';

const modules = [
  MatDialogModule,
  AssignmentModule,
  CustomersModule,
  EmployeesModule,
  ProjectsModule,
  HoursOverviewModule,
];
@NgModule({
  imports: [CommonModule, ...modules],
  exports: [...modules],
  providers: [{ provide: MatDialogRef, useValue: {} }, { provide: MAT_DIALOG_DATA, useValue: {} }, EmployeesService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
