import moment, { Moment } from 'moment';

export class Week {
  private _startOfWeek: Moment = moment().startOf('isoWeek');

  constructor(startOfWeek: Moment = moment()) {
    this._startOfWeek = startOfWeek.startOf('isoWeek');
  }

  public get startOfWeek(): Moment {
    return this._startOfWeek;
  }

  public set startOfWeek(start: Moment) {
    this._startOfWeek = start;
  }

  public get weekNumber(): number {
    return this._startOfWeek.week();
  }

  public equals(week: Week): boolean {
    return this._startOfWeek.isSame(week._startOfWeek, 'week');
  }
}
