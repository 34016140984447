import { Model } from './model';
import { CustomerListObject as CustomerListObjectDTO } from 'generated-sources';

export class CustomerListObject extends Model {
  public name: string;

  constructor(raw: CustomerListObjectDTO) {
    super();
    this.id = raw.id;
    this.name = raw.name;
    this.deletable = !!raw.deletable;
  }

  public isInternal(): boolean {
    return this.name.trim().startsWith('XLence');
  }
}
