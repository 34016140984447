import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
@Injectable({
  providedIn: 'root',
})
export class PendingService {
  private pending = new Map<string, Observable<any>>();

  public intercept(method: string, url: string, query: any, request): Observable<any> {
    const key = this.urlKey(method, url, query?.params?.map);
    const pendingRequestObservable = this.pending.get(key);
    return pendingRequestObservable ? pendingRequestObservable : this.sendRequest(key, request);
  }

  private urlKey(method: string, url: string, params): string {
    return params ? `${method}:${url}-${Array.from(params.entries()).toString()}` : `${method}:${url}`;
  }

  public sendRequest(key, request): Observable<any> {
    this.pending.set(key, request);
    return request.pipe(
      finalize(() => {
        this.pending.delete(key);
      }),
    );
  }
}
