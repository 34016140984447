import { CommonModule } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { LocaleService } from 'src/app/shared/services';
import { AdminModule } from './admin/admin.module';
import { CustomersModule } from './admin/customers/customers.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LoginModule } from './core/login/login.module';
import { EmployeeModule } from './employee/employee.module';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { PageLayoutComponent } from './layout/page-layout/page-layout.component';
import { SharedModule } from './shared/shared.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}

const sections = [CoreModule, SharedModule, EmployeeModule, CustomersModule, AdminModule, LoginModule];

@NgModule({
  declarations: [AppComponent, AppLayoutComponent, PageLayoutComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    NgxTrimDirectiveModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ...sections,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'nl-NL' }, LocaleService, provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
