<div id="customer-add">
  <h2>Klant toevoegen</h2>
  <div class="header-wrapper">
    <mat-accordion multi>
      <form
        #form="ngForm"
        (change)="dirty = true"
        (ngSubmit)="dirty && !form.invalid && submit(form)"
        [formGroup]="contentForm"
      >
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> Klantgegevens </mat-panel-title>
            <mat-panel-description>
              <mat-icon color="primary">work</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label>{{"Naam" | translate}}</mat-label>
            <input type="text" matInput formControlName="name" (keyup)="keyup($event)" required trim="blur" #name />
            <mat-error *ngIf="contentForm.controls.name.errors?.required">Veld moet worden ingevuld</mat-error>
          </mat-form-field>
        </mat-expansion-panel>
        <div class="actions">
          <button type="reset" mat-raised-button (click)="cancel()">
            <mat-icon>close</mat-icon>
            Annuleren
          </button>
          <button type="submit" mat-raised-button color="primary" [disabled]="!dirty || form.invalid">
            <mat-icon>add</mat-icon>
            Toevoegen
          </button>
        </div>
      </form>
    </mat-accordion>
  </div>
</div>
