import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';

import { MaterialModule } from '../../material.module';
import { WeekHoursComponent } from './week-hours/week-hours.component';
import { PipesModule } from '../../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { HoursInputComponent } from './hours-input/hours-input.component';
import { MatInputHourifiedDirective } from './hours-input/mat-input-hourified.directive';
import { MatInputMinutyfiedDirective } from './hours-input/mat-input-minutyfied.directive';

@NgModule({
  declarations: [WeekHoursComponent, HoursInputComponent, MatInputHourifiedDirective, MatInputMinutyfiedDirective],
  exports: [WeekHoursComponent, HoursInputComponent, MatInputHourifiedDirective, MatInputMinutyfiedDirective],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, MomentModule, PipesModule, TranslateModule],
})
export class WeekHoursModule {}
