import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ExportEffortDialogComponent } from 'src/app/shared/components/dialogs/export-effort-dialog/export-effort-dialog.component';
import { EmployeeListObject } from 'src/app/shared/models';
import { EffortsService, EmployeesService, FeedbackService } from 'src/app/shared/services';

@Component({
  selector: 'app-hours-overview',
  templateUrl: './hours-overview.component.html',
  styleUrls: ['./hours-overview.component.scss'],
})
export class HoursOverviewComponent implements OnInit {
  displayedColumns: string[] = ['lastName', 'firstName', 'icons'];

  dataSource = new MatTableDataSource<EmployeeListObject>([]);
  filterControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public employeesService: EmployeesService,
    public router: Router,
    private feedback: FeedbackService,
    private effortsService: EffortsService,
  ) {}

  ngOnInit(): void {
    this.employeesService.getOrFetchAll().subscribe((result) => {
      this.dataSource = new MatTableDataSource<EmployeeListObject>(result);
      this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
        if (typeof data[sortHeaderId] === 'string') {
          return data[sortHeaderId].toLocaleLowerCase();
        }
        return data[sortHeaderId];
      };
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });

    this.filteredOptions = this.filterControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value)),
    );
  }

  showExportDialog(): Observable<unknown> {
    const data = {
      callback: this.effortsService.downloadEfforts.bind(this.effortsService),
    };
    return this.feedback.dialog
      .open(ExportEffortDialogComponent, {
        width: '33%',
        data: data,
      })
      .afterClosed();
  }

  private _filter(value: string): string[] {
    const filterValue = value.trim().toLowerCase();
    const result = [];
    if (filterValue.length === 0) return result;

    for (const item of this.dataSource.filteredData) {
      if (item.lastName.toLowerCase().indexOf(filterValue) >= 0) {
        result.push(item.lastName);
      } else if (item.firstName.toLowerCase().indexOf(filterValue) >= 0) {
        result.push(item.firstName);
      }
    }
    return result;
  }

  // Zoekfunctie !
  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim().toLowerCase(); // Remove whitespace and datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  link(row: EmployeeListObject): void {
    const now = moment().subtract(1, 'months');
    this.router.navigate(['admin', 'urenoverzicht', row.id, now.year(), now.month() + 1]);
  }

  onFeedback(row: EmployeeListObject, event: MouseEvent): void {
    event.stopPropagation();
    this.feedback.getHolidayHoursInformation(row.id).subscribe({
      error: (err) => {
        this.feedback.openErrorToast(err);
      },
    });
  }

  isPastEmployment(row: EmployeeListObject): boolean {
    return row.hasContractEnded;
  }

  isPastHolidayYear(row: EmployeeListObject): boolean {
    const holidayYear = row.holidayYear;
    const currentYear = moment().year();
    return !this.isPastEmployment(row) && holidayYear < currentYear;
  }
}
