import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import './environments/environment';

if (ura_environment.production) {
  enableProdMode();
  console.log = function () {
    // no implementation required
  };
}

platformBrowserDynamic().bootstrapModule(AppModule, {});
