import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { EmployeesOverviewComponent } from './employees-overview/employees-overview.component';
import { MaterialModule } from '../../shared/material.module';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { EmployeeAddComponent } from './employee-add/employee-add.component';
import { UraOverviewModule } from 'src/app/shared/components/ura-overview/ura-overview.module';
import { ArchiveOverviewComponent } from './archive-overview/archive-overview.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EmployeesOverviewComponent, EmployeeEditComponent, EmployeeAddComponent, ArchiveOverviewComponent],
  exports: [EmployeesOverviewComponent, EmployeeAddComponent],
  imports: [
    CommonModule,
    MaterialModule,
    MatExpansionModule,
    NgxTrimDirectiveModule,
    UraOverviewModule,
    TranslateModule,
  ],
})
export class EmployeesModule {}
