import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment, { Moment } from 'moment';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Customer, Employee, EmployeeListObject, ProjectListObject } from 'src/app/shared/models';
import { CustomersService, EmployeesService, ProjectsService } from 'src/app/shared/services';

class CustomerWithProjects {
  customer: Customer;
  projects: ProjectListObject[] = [];
}

interface DialogInput {
  employee?: Employee;
  month?: Moment;
  callback: (
    from: Moment,
    until: Moment,
    employees: number[],
    projects: number[],
    granularity: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR',
  ) => void;
}

@Component({
  selector: 'app-export-effort-dialog',
  templateUrl: './export-effort-dialog.component.html',
  styleUrls: ['./export-effort-dialog.component.scss'],
})
export class ExportEffortDialogComponent implements OnInit {
  contentForm: UntypedFormGroup;

  employees: EmployeeListObject[] = [];
  filteredEmployees = new ReplaySubject<EmployeeListObject[]>(1);

  projectsByCustomer: CustomerWithProjects[] = [];
  filteredProjectsByCustomer = new ReplaySubject<CustomerWithProjects[]>(1);

  private _onClose = new Subject<void>();

  constructor(
    public employeesService: EmployeesService,
    public projectsService: ProjectsService,
    public customersService: CustomersService,
    public dialogRef: MatDialogRef<ExportEffortDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogInput,
    private formBuilder: UntypedFormBuilder,
  ) {}

  private static compareCustomer(c1: CustomerWithProjects, c2: CustomerWithProjects) {
    if (c1.customer.isInternal() !== c2.customer.isInternal()) {
      return c1.customer.isInternal() ? -1 : 1;
    } else {
      return c1.customer.name.localeCompare(c2.customer.name);
    }
  }

  private static compareProjects(p1: ProjectListObject, p2: ProjectListObject) {
    return p1.name.localeCompare(p2.name);
  }

  private static getGranularity(monthCount: number): string {
    if (monthCount > 3) {
      return 'MONTH';
    } else if (monthCount > 0) {
      return 'WEEK';
    }
    return 'DAY';
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;

    this.contentForm = this.formBuilder.group({
      dateFrom: ['', Validators.compose([Validators.required])],
      dateUntil: ['', Validators.compose([Validators.required])],
      employeesFilterControl: ['', []],
      employeesSelection: ['', []],
      projectsFilterControl: ['', []],
      projectsSelection: ['', []],
      granularity: ['', []],
    });

    this.employeesService.getAll().subscribe((employees) => {
      employees.sort((elo1, elo2) => {
        return elo1.lastName === elo2.lastName
          ? elo1.firstName.localeCompare(elo2.firstName)
          : elo1.lastName.localeCompare(elo2.lastName);
      });
      this.employees = employees;
      this.filteredEmployees.next(this.employees);
    });

    combineLatest([this.contentForm.controls.dateFrom.valueChanges, this.contentForm.controls.dateUntil.valueChanges])
      .pipe(takeUntil(this._onClose))
      .subscribe(([from, until]) => {
        // Default to: per day within a single month; per week up to 3 months, per month otherwise.
        const monthCount = until.getMonth() - from.getMonth();
        this.contentForm.controls.granularity.setValue(ExportEffortDialogComponent.getGranularity(monthCount));
      });

    this.contentForm.controls.employeesFilterControl.valueChanges
      .pipe(takeUntil(this._onClose))
      .subscribe((filterValue) => this.filterEmployees(filterValue));

    combineLatest([this.customersService.getAll(), this.projectsService.getAll()]).subscribe(
      ([customers, projects]) => {
        this.setProjects(
          customers.map((c) => new Customer(c)),
          projects,
        );
      },
    );

    this.contentForm.controls.projectsFilterControl.valueChanges
      .pipe(takeUntil(this._onClose))
      .subscribe((filterValue) => this.filterProjects(filterValue));

    if (this.data.month) {
      this.contentForm.controls.dateFrom.setValue(this.data.month.startOf('month').toDate());
      this.contentForm.controls.dateUntil.setValue(this.data.month.endOf('month').toDate());
      this.contentForm.controls.granularity.setValue('DAY');
    }
    if (this.data.employee) {
      this.contentForm.controls.employeesSelection.setValue([this.data.employee]);
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true);
    this._onClose.next();
    this._onClose.complete();
    this.data.callback(
      moment(this.contentForm.controls.dateFrom.value),
      moment(this.contentForm.controls.dateUntil.value),
      (this.contentForm.controls.employeesSelection.value || []).map((e) => e.id),
      (this.contentForm.controls.projectsSelection.value || []).map((p) => p.id),
      this.contentForm.controls.granularity.value,
    );
  }

  onDismiss(): void {
    this.dialogRef.close(false);
    this._onClose.next();
    this._onClose.complete();
  }

  public hasEqualId(a: { id: number }, b: { id: number }): boolean {
    return a === b || (a && b && a.id === b.id);
  }

  private setProjects(customers: Customer[], projects: ProjectListObject[]) {
    const customersById: { [key: number]: CustomerWithProjects } = {};
    customers.forEach((customer) => {
      customersById[customer.id] = { customer: customer, projects: [] };
    });
    projects.forEach((project) => {
      customersById[project.customerId].projects.push(project);
    });
    this.projectsByCustomer = Object.values(customersById);

    this.projectsByCustomer.sort(ExportEffortDialogComponent.compareCustomer);
    //eslint-disable-next-line sonarjs/no-misleading-array-reverse
    this.projectsByCustomer.forEach((cwp) => cwp.projects.sort(ExportEffortDialogComponent.compareProjects));

    this.filteredProjectsByCustomer.next(this.projectsByCustomer);
  }

  private filterEmployees(search) {
    if (!this.employees) {
      return;
    }
    if (!search) {
      this.filteredEmployees.next(this.employees.slice());
    } else {
      const searchLower = search.toLowerCase();
      this.filteredEmployees.next(
        this.employees.filter(
          (employee) =>
            employee.firstName.toLowerCase().indexOf(searchLower) > -1 ||
            employee.lastName.toLowerCase().indexOf(searchLower) > -1,
        ),
      );
    }
  }

  private filterProjects(search) {
    const searchLower = search.toLowerCase();

    const nameMatches = (name) => name.toLowerCase().indexOf(searchLower) > -1;

    this.filteredProjectsByCustomer.next(
      this.projectsByCustomer
        .map((cwp) => {
          return nameMatches(cwp.customer.name)
            ? { customer: cwp.customer, projects: cwp.projects.slice() }
            : { customer: cwp.customer, projects: cwp.projects.filter((project) => nameMatches(project.name)) };
        })
        .filter((cwp) => cwp.projects.length),
    );
  }
}
