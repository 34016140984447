<div id="submit-hours-dialog" mat-dialog-content>
  <p>Weet u zeker dat u de uren van deze week wilt indienen?</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="onDismiss()">
    <mat-icon>close</mat-icon>
    Annuleren
  </button>
  <button mat-raised-button color="primary" (click)="onConfirm()">
    <mat-icon>assignment_turned_in</mat-icon>
    Uren indienen
  </button>
</div>
