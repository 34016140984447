import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    public router: Router,
    private loginService: LoginService,
  ) {}

  canActivate(): boolean {
    if (!this.loginService.isLoggedIn()) {
      this.router.navigate(['login'], {
        queryParams: {
          redirect: this.router.getCurrentNavigation().extractedUrl.toString(),
        },
      });
      return false;
    }
    return true;
  }
}
