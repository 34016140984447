import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: 'input[appMatInputHourified]',
  providers: [{ provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputHourifiedDirective }],
})
export class MatInputHourifiedDirective implements ControlValueAccessor {
  private _value: string | null;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  @Input()
  get value(): string | null {
    return this._value;
  }
  set value(value: string | null) {
    this._value = value;
    this.cleanValue();
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appMatInputHourified') hourValue: { hourPart: number; minutePart: number };

  private cleanValue() {
    if (!(+this.elementRef.nativeElement.value > 0 || this.hourValue?.minutePart)) {
      this.elementRef.nativeElement.value = '';
    } else if (+this.elementRef.nativeElement.value <= 0) {
      this.elementRef.nativeElement.value = '0';
    }
  }

  writeValue(obj: string | null): void {
    this.value = obj;
  }

  @HostListener('blur')
  _onBlur(): void {
    this.cleanValue();
  }

  _onChange(): void {
    this.cleanValue();
  }

  registerOnChange(fn: () => void): void {
    this._onChange = fn;
  }

  registerOnTouched(): void {
    // no implementation required
  }
}
