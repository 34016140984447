import { Component, OnInit } from '@angular/core';
import { CustomMenuItemsService } from 'src/app/shared/services';
// eslint-disable-next-line no-restricted-imports
import { MenuItem } from 'src/app/shared/models/classes/menuItem';

@Component({
  selector: 'app-custom-menu-items',
  templateUrl: './custom-menu-items.component.html',
  styleUrls: ['./custom-menu-items.component.scss'],
})
export class CustomMenuItemsComponent implements OnInit {
  menuItemArray: MenuItem[] = [];

  constructor(private customMenuItemsService: CustomMenuItemsService) {}

  ngOnInit(): void {
    this.customMenuItemsService.getMenuItemsList().subscribe((items) => {
      this.menuItemArray = items;
    });
  }

  getCustomMenuItems(): MenuItem[] {
    return this.menuItemArray;
  }
}
