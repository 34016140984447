<section mat-dialog-container cdkDrag cdkDragRootElement=".cdk-overlay-pane" id="hours-overview-day-edit-dialog">
  <h1 mat-dialog-title>Uren wijzigen</h1>

  <div mat-dialog-content>
    <button mat-raised-button (click)="decrement()">-</button>

    <input
      matInput
      type="number"
      [(ngModel)]="hours"
      name="hours"
      (change)="change()"
      (keyup)="change()"
      style="text-align: center; width: auto; -webkit-appearance: none; -moz-appearance: textfield"
      #urenInput
      maxlength="5"
      min="-24"
      max="24"
    />

    <button mat-raised-button (click)="increment()">+</button>

    <div style="padding: 20px; padding-left: 10px">
      <mat-checkbox [(ngModel)]="submitted" (change)="change()" [disabled]="approved">Ingediend</mat-checkbox>
    </div>
  </div>
  <div mat-dialog-actions style="display: flex; align-items: center; gap: 10px">
    <button type="submit" mat-raised-button (click)="cancel()">
      <mat-icon>close</mat-icon>
      Annuleren
    </button>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="!dirty"
      (click)="submit()"
      style="margin-left: 0"
    >
      <mat-icon>{{ day.data.id ? 'done' : 'add' }}</mat-icon>
      {{ day.data.id ? 'Opslaan' : 'Toevoegen' }}
    </button>
  </div>
</section>
