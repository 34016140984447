<div id="employee-add">
  <h2>Medewerker toevoegen</h2>
  <div class="header-wrapper">
    <mat-accordion multi="true">
      <form #form="ngForm" (ngSubmit)="submit(form)" [formGroup]="contentForm">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> Persoonlijke gegevens </mat-panel-title>
            <mat-panel-description>
              <mat-icon color="primary">face</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label>{{"Gebruikersnaam" | translate}}</mat-label>
            <input
              type="text"
              matInput
              formControlName="userName"
              autocomplete="new-password"
              required
              trim="blur"
              #userName
            />
            <mat-error *ngIf="contentForm.controls.userName.errors?.maxlength"
              >Veld mag maximaal 30 lang zijn
            </mat-error>
            <mat-error *ngIf="contentForm.controls.userName.errors?.minlength"
              >Gebruikersnaam moet minimaal 5 karakters bevatten
            </mat-error>
            <mat-error *ngIf="contentForm.controls.userName.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error
              *ngIf="!contentForm.controls.userName.errors?.minlength && contentForm.controls.userName.errors?.pattern"
              >Gebruikersnaam moet met een letter beginnen, en mag enkel letters, cijfers en streepjes bevatten
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{"Roepnaam" | translate}}</mat-label>
            <input type="text" matInput formControlName="nickName" required trim="blur" />
            <mat-error *ngIf="contentForm.controls.nickName.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.nickName.errors?.maxlength"
              >Veld mag maximaal 40 lang zijn
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{"Voornaam" | translate}}</mat-label>
            <input type="text" matInput formControlName="firstName" required trim="blur" />
            <mat-error *ngIf="contentForm.controls.firstName.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.firstName.errors?.maxlength"
              >Veld mag maximaal 50 lang zijn
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{"Achternaam" | translate}}</mat-label>
            <input type="text" matInput formControlName="lastName" required trim="blur" />
            <mat-error *ngIf="contentForm.controls.lastName.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.lastName.errors?.maxlength"
              >Veld mag maximaal 40 lang zijn
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "E-mailadres" | translate}}</mat-label>
            <input type="email" matInput formControlName="email" required trim="blur" />
            <mat-error *ngIf="contentForm.controls.email.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.email.errors?.pattern">Ongeldig e-mailadres</mat-error>
          </mat-form-field>
          <br />
          <div formGroupName="roles" class="roles">
            <mat-label class="roles_label">Gebruikersrollen *</mat-label>
            <mat-checkbox id="cbAdministrator" formControlName="administrator">Admin</mat-checkbox>
            <mat-checkbox id="cbAccounting" formControlName="accounting">Financieel medewerker</mat-checkbox>
            <mat-checkbox id="cbContentManager" formControlName="contentManager">Inhoudsbeheerder</mat-checkbox>
            <mat-checkbox id="cbEmployee" formControlName="employee">Medewerker</mat-checkbox>
          </div>
          <mat-error class="roles_error" *ngIf="contentForm.controls.roles.errors?.rolesRequired"
            >Veld moet worden ingevuld
          </mat-error>
        </mat-expansion-panel>
        <br />
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Contractgegevens </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-form-field>
            <mat-label>{{'contract.startDateContract' | translate}}</mat-label>
            <input
              matInput
              [matDatepicker]="contractDate"
              formControlName="contractDate"
              name="contractDate"
              readonly
              required
              (click)="contractDate.open()"
            />
            <mat-datepicker-toggle matSuffix [for]="contractDate"></mat-datepicker-toggle>
            <mat-datepicker #contractDate></mat-datepicker>
            <mat-error *ngIf="contentForm.controls.contractDate.errors?.required">Veld moet worden ingevuld</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'contract.hoursOnContract' | translate}}</mat-label>
            <input
              matInput
              class="numberHours"
              type="number"
              formControlName="contractHours"
              required
              (beforeinput)="acceptNumericOnly($event)"
              min="1"
            />
            <mat-error *ngIf="contentForm.controls.contractHours.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.contractHours.errors?.pattern">Ongeldig aantal uren</mat-error>
            <mat-error *ngIf="contentForm.controls.contractHours.errors?.max"
              >Mag maximaal {{ contentForm.controls.contractHours.errors?.max.max }} zijn
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{'hours.holidayHoursPreviousYear' | translate:{year:lastYear} }}</mat-label>
            <input
              class="numberHours"
              type="number"
              matInput
              formControlName="holidayHoursPreviousYear"
              required
              (beforeinput)="acceptNumericOnly($event)"
            />
            <mat-error *ngIf="contentForm.controls.holidayHoursPreviousYear.errors?.required"
              >Veld moet worden ingevuld
            </mat-error>
            <mat-error *ngIf="contentForm.controls.holidayHoursPreviousYear.errors?.pattern"
              >Ongeldig aantal uren (max. 3 cijfers en 2 decimalen)
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{'hours.holidayHoursCurrentYear' | translate}}</mat-label>
            <input
              class="numberHours"
              type="number"
              matInput
              formControlName="holidayHoursCurrentYear"
              required
              (beforeinput)="acceptNumericOnly($event)"
            />
            <mat-error *ngIf="contentForm.controls.holidayHoursCurrentYear.errors?.required"
              >Veld moet worden ingevuld
            </mat-error>
            <mat-error *ngIf="contentForm.controls.holidayHoursCurrentYear.errors?.pattern"
              >Ongeldig aantal uren (max. 3 cijfers en 2 decimalen)
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{'hours.submittedHolidayHours' | translate}}</mat-label>
            <input
              class="numberHours"
              type="number"
              matInput
              formControlName="approvedHolidayHours"
              required
              (beforeinput)="acceptNumericOnly($event)"
            />
            <mat-error *ngIf="contentForm.controls.approvedHolidayHours.errors?.required"
              >Veld moet worden ingevuld
            </mat-error>
            <mat-error *ngIf="contentForm.controls.approvedHolidayHours.errors?.pattern"
              >Ongeldig aantal uren (max. 3 cijfers en 2 decimalen)
            </mat-error>
          </mat-form-field>
          <br />
        </mat-expansion-panel>
        <div class="actions">
          <button type="button" mat-raised-button (click)="cancel()">
            <mat-icon>close</mat-icon>
            Annuleren
          </button>
          <button type="button" mat-raised-button color="primary" [disabled]="form.invalid" (click)="submit(form)">
            <mat-icon>add</mat-icon>
            Toevoegen
          </button>
        </div>
      </form>
    </mat-accordion>
  </div>
</div>
