import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { EmployeesService } from './employees.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private employeeService: EmployeesService,
  ) {}

  public logIn(username: string, password: string): Promise<unknown> {
    return this.authService.logIn(username, password).then(() => {
      return this.logInWithExistingToken();
    });
  }

  public logInWithExistingToken(): Promise<unknown> {
    return this.employeeService.getLoggedInEmployee().then((employee) => {
      this.userService.setLoggedInUser(employee);
    });
  }

  public logOut(): void {
    this.authService.logOut();
    this.userService.setLoggedInUser(null);
    window.location.href = '/';
  }

  isLoggedIn(): boolean {
    return this.authService.hasValidToken() && !!this.userService.getLoggedInUser();
  }
}
