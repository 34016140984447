import { Model } from './model';
import moment, { Moment } from 'moment';
import { Employee as EmployeeDTO } from 'generated-sources';

export class Employee extends Model {
  userName: string;
  lastName: string;
  firstName: string;
  nickName: string;
  email: string;
  generated: string;
  deletable: boolean;
  contractDate: Moment;
  endDate?: Moment;
  contractHours?: number;
  holidayHoursCurrentYear?: number;
  holidayHoursPreviousYear?: number;
  approvedHolidayHours?: number;
  submittedHolidayHours?: number;
  holidayYear: number;
  roles: Array<string>;

  constructor(dto: EmployeeDTO) {
    super();
    this.id = dto.id;
    this.userName = dto.userName;
    this.lastName = dto.lastName;
    this.firstName = dto.firstName;
    this.nickName = dto.nickName;
    this.email = dto.email;
    this.generated = dto.generated;
    this.deletable = !!dto.deletable;
    this.contractDate = dto.contractDate && moment(dto.contractDate);
    this.endDate = dto.endDate && moment(dto.endDate);
    this.contractHours = dto.contractHours;
    this.holidayHoursCurrentYear = dto.holidayHoursCurrentYear;
    this.holidayHoursPreviousYear = dto.holidayHoursPreviousYear;
    this.approvedHolidayHours = dto.approvedHolidayHours;
    this.submittedHolidayHours = dto.submittedHolidayHours;
    this.holidayYear = dto.holidayYear;
    this.roles = dto.roles;
  }

  public static copy(orig: Employee): Employee {
    return new Employee({
      id: orig.id,
      userName: orig.userName,
      lastName: orig.lastName,
      firstName: orig.firstName,
      nickName: orig.nickName,
      email: orig.email,
      generated: orig.generated,
      deletable: !!orig.deletable,
      contractDate: orig.contractDate.toISOString(),
      endDate: orig.endDate?.toISOString(),
      contractHours: orig.contractHours,
      holidayHoursCurrentYear: orig.holidayHoursCurrentYear,
      holidayHoursPreviousYear: orig.holidayHoursPreviousYear,
      approvedHolidayHours: orig.approvedHolidayHours,
      submittedHolidayHours: orig.submittedHolidayHours,
      holidayYear: orig.holidayYear,
      roles: orig.roles,
    });
  }

  public get name(): string {
    return this.firstName + ' ' + this.lastName;
  }

  public toString(): string {
    return this.name;
  }
}
