import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeesService, FeedbackService } from 'src/app/shared/services';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  public password: string;
  public repeated: string;
  public sent = false;
  public hide = true;
  public readonly passwordPattern =
    // eslint-disable-next-line sonarjs/regex-complexity,sonarjs/concise-regex
    /^(?=.{8,}$)(?=(?:.*?[A-Z]){2})(?=.*?[a-z])(?=(?:.*?[0-9]){1})(?=.*[!"#$%&'()*+,\\\-./:;<=>?@[\]^_`{|}~]).*$/;

  private token: string;

  constructor(
    private route: ActivatedRoute,
    private employeesService: EmployeesService,
    private feedbackService: FeedbackService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.token = params.token;
    });
  }

  setPassword(): void {
    this.employeesService.setPassword(this.password, this.token).subscribe({
      next: () => {
        this.sent = true;
      },
      error: (error) => {
        const errMessage =
          error.error.message === 'Reset token is invalid'
            ? 'Wachtwoord link is niet meer geldig, probeer je wachtwoord opnieuw te resetten'
            : 'Het wachtwoord moet minimaal 8 karakters lang zijn en 2 hoofdletters, 1 getal en 1 speciaal teken bevatten.';
        this.feedbackService.openErrorToast(errMessage);
      },
    });
  }

  cancel(): void {
    this.location.back();
  }
}
