import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { KeyUp } from 'src/app/shared/helper/keyup';
import { UraValidators } from 'src/app/shared/helper/ura-validators';
import { Customer, CustomerListObject, Project } from 'src/app/shared/models';
import { CustomersService, FeedbackService, ProjectsService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['../../general.component.scss'],
})
export class ProjectAddComponent implements OnInit, AfterViewInit {
  dirty = false;
  project: Project = new Project();
  // ViewChild for autofocus, as of 3-3-2020 the autofocus attribute in html is still broken and stops working after cancelling the form once
  // and then reentering te form, in order to keep it working this has been implemented
  // See: https://stackoverflow.com/questions/56394068/autofocus-not-working-after-remove-and-adding-again-to-the-dom
  @ViewChild('name', { static: true }) focusElement: ElementRef;
  contentForm: UntypedFormGroup;
  customers: CustomerListObject[] = [];

  readonly sortByName = function (cust1: Customer, cust2: Customer): number {
    if (cust1.name > cust2.name) {
      return 1;
    }
    if (cust1.name < cust2.name) {
      return -1;
    }
    return 0;
  };

  constructor(
    private router: Router,
    private feedback: FeedbackService,
    public projectsService: ProjectsService,
    public customersService: CustomersService,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.contentForm = new UntypedFormGroup(
      {
        name: new UntypedFormControl('', [Validators.required]),
        klant: new UntypedFormControl('', [Validators.required]),
        startDate: new UntypedFormControl('', [Validators.required]),
        endDate: new UntypedFormControl('', []),
        workFromHomeAllowed: new UntypedFormControl(true, []),
      },
      { validators: UraValidators.dateRangeValidator },
    );

    if (!this.project) {
      this.project = new Project();
    }

    this.customersService.getOrFetchAll().subscribe((customers) => {
      // eslint-disable-next-line sonarjs/no-misleading-array-reverse
      this.customers = customers.sort(this.sortByName);
    });
  }

  keyup(e: KeyboardEvent): void {
    if (KeyUp.charactersAndBackspace(e)) this.dirty = true;
  }

  private setProject(): void {
    const customer = this.contentForm.get('klant').value;

    this.project.endDate = moment(this.contentForm.get('endDate').value);
    this.project.customerId = customer.id;
    this.project.customer = new Customer({ id: customer.id, name: customer.name });
    this.project.startDate = moment(this.contentForm.get('startDate').value);
    this.project.name = this.contentForm.get('name').value;
    this.project.workFromHomeAllowed = this.contentForm.get('workFromHomeAllowed').value;
  }

  submit(): void {
    this.setProject();

    this.projectsService.createOrUpdate(this.project).subscribe({
      next: () => {
        this.router.navigate(['admin', 'projecten']);
        this.feedback.openSuccessToast(this.translateService.instant('Het nieuwe project is toegevoegd'));
      },
      error: (err) => {
        this.router.navigate(['admin', 'projecten']);
        this.feedback.openErrorToast(err);
      },
    });
  }

  cancel(): void {
    this.feedback.openNeutralToast();
    this.router.navigate(['admin', 'projecten']);
  }

  ngAfterViewInit(): void {
    this.focusElement.nativeElement.focus();
    this.changeDetector.detectChanges();
  }
}
