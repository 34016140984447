import { Injectable } from '@angular/core';
import {
  Configuration,
  Project as ProjectDTO,
  ProjectListObject as ProjectListObjectDTO,
  ProjectsService as Swagger,
} from 'generated-sources';
import { Observable } from 'rxjs';
import { Project, ProjectListObject } from 'src/app/shared/models';
import { HttpService } from './http';
import { ResourceService } from './resource.service';

@Injectable()
export class ProjectsService extends ResourceService<Project, ProjectDTO, ProjectListObject, ProjectListObjectDTO> {
  private readonly proxy: Swagger;

  protected get name(): string {
    return 'project';
  }

  constructor(private http: HttpService) {
    super(http);
    this.proxy = new Swagger(http.asClient, this.backend, new Configuration());
  }

  create(project: Project): Observable<Project> {
    const dto = this.modelToDto(project);
    return this.proxy.createProjectUsingPOST(dto).pipe(this.mapSingle);
  }

  fetch(id: number): Observable<Project> {
    return this.proxy.getProjectUsingGET(id).pipe(this.mapSingle);
  }

  getAll(): Observable<Array<ProjectListObject>> {
    return this.proxy.getProjectsForInquiryUsingGET().pipe(this.mapList);
  }

  update(id: number, project: Project): Observable<Project> {
    const dto = this.modelToDto(project);
    return this.proxy.updateProjectUsingPUT(dto, dto.id).pipe(this.mapSingle);
  }

  delete(id: number): Observable<unknown> {
    return this.proxy.deleteProjectUsingDELETE(id);
  }

  protected modelToDto(a: Project): ProjectDTO {
    return {
      id: a.id,
      name: a.name,
      customer: a.customer,
      startDate: a.startDateString,
      endDate: a.endDateString,
      workFromHomeAllowed: a.workFromHomeAllowed,
    };
  }

  protected dtoToModel(dto: ProjectDTO): Project {
    return new Project(dto);
  }

  protected listDtoToModel(dto: ProjectListObjectDTO): ProjectListObject {
    return new ProjectListObject(dto);
  }
}
