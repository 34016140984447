import { Routes } from '@angular/router';

import { HoursEntryComponent } from './hours-entry/hours-entry.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { HoursOverviewDetailComponent } from '../admin/hours-overview/hours-overview-detail/hours-overview-detail.component';

export const employeeRoutes: Routes = [
  {
    path: '',
    children: [
      { path: 'uren', redirectTo: 'uren/' },
      {
        path: 'uren/',
        component: HoursEntryComponent,
        data: {
          icon: 'border_color',
          menuTranslateKey: 'menu.enter-hours',
        },
      },
      {
        path: 'uren/:year/:week',
        component: HoursEntryComponent,
      },
      {
        path: 'reset/:token',
        component: SetPasswordComponent,
      },
      {
        path: 'forgot',
        component: ForgotPasswordComponent,
      },
      {
        path: 'set/:token',
        component: SetPasswordComponent,
      },
      {
        path: 'newPassword',
        component: NewPasswordComponent,
      },
      {
        path: 'urenoverzicht/:id/:year/:month',
        component: HoursOverviewDetailComponent,
      },
    ],
  },
];
