<div id="menuItem-details">
  <h2>MenuItem bewerken</h2>
  <div class="header-wrapper">
    <mat-accordion multi>
      <form id="form" #form="ngForm" (ngSubmit)="!contentForm.invalid && submit(form)" [formGroup]="contentForm">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> Custom Menu Itemgegevens </mat-panel-title>
            <mat-panel-description>
              <mat-icon color="primary">category</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field>
            <input type="text" matInput placeholder="name" formControlName="name" required trim="blur" />
            <mat-error *ngIf="contentForm.controls.name.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.name.errors?.maxlength">Veld mag maximaal 50 lang zijn </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              class="url-field"
              type="text"
              matInput
              placeholder="url"
              formControlName="url"
              required
              trim="blur"
            />
            <mat-error *ngIf="contentForm.controls.url.errors?.required">Veld moet worden ingevuld</mat-error>
            <mat-error *ngIf="contentForm.controls.url.errors?.pattern">Voer een geldige URL in </mat-error>
          </mat-form-field>
          <br />
        </mat-expansion-panel>
        <div class="actions">
          <button type="reset" mat-raised-button (click)="cancel()">
            <mat-icon>close</mat-icon>
            Annuleren
          </button>
          <button type="submit" form="form" mat-raised-button color="primary" [disabled]="form.invalid">
            <mat-icon>done</mat-icon>
            Opslaan
          </button>
        </div>
      </form>
    </mat-accordion>
  </div>
</div>
