<div id="assignment-overview">
  <h2>Inzetoverzicht</h2>
  <div class="header-wrapper">
    <mat-form-field class="mat-mdc-form-field">
      <input
        matInput
        (keyup)="applyFilter($any($event.target).value)"
        placeholder="Filter"
        [formControl]="filterControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let row of filteredOptions | async" [value]="row"> {{ row }} </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button mat-raised-button color="primary" class="mw-add-button" (click)="addAssignment()">
      <mat-icon class="add-icon">add_circle</mat-icon>Inzet toevoegen
    </button>
  </div>
  <div class="mat-elevation-z8">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Opties" class="icon_more_vert">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="showsStandardAssignments(!showStandardAssignments)">
        <mat-icon *ngIf="!showStandardAssignments">visibility</mat-icon>
        <mat-icon *ngIf="showStandardAssignments">visibility_off</mat-icon>
        <span *ngIf="!showStandardAssignments">Standaardinzetten weergeven</span>
        <span *ngIf="showStandardAssignments">Standaardinzetten verbergen</span>
      </button>
      <button mat-menu-item (click)="showsExpiredAssignments(!showExpiredAssignments)">
        <mat-icon *ngIf="!showExpiredAssignments">alarm</mat-icon>
        <mat-icon *ngIf="showExpiredAssignments">alarm_off</mat-icon>
        <span *ngIf="!showExpiredAssignments">Verlopen inzetten weergeven</span>
        <span *ngIf="showExpiredAssignments">Verlopen inzetten verbergen</span>
      </button>
    </mat-menu>

    <table mat-table class="full-width-table" [dataSource]="dataSource" matSort aria-label="Elements">
      <!-- Name Column -->
      <ng-container matColumnDef="employee.lastName">
        <th scope="row" mat-header-cell *matHeaderCellDef mat-sort-header>Achternaam</th>
        <td mat-cell *matCellDef="let row">{{ row.employee?.lastName }}</td>
      </ng-container>
      <ng-container matColumnDef="employee.firstName">
        <th scope="row" mat-header-cell *matHeaderCellDef mat-sort-header>Voornaam</th>
        <td mat-cell *matCellDef="let row">{{ row.employee?.firstName }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="project.name">
        <th scope="row" mat-header-cell *matHeaderCellDef mat-sort-header>Project</th>
        <td mat-cell *matCellDef="let row">{{ row.project?.name }}</td>
      </ng-container>

      <!-- Client Column -->
      <ng-container matColumnDef="customer.name">
        <th scope="row" mat-header-cell *matHeaderCellDef mat-sort-header>Klant</th>
        <td mat-cell *matCellDef="let row">{{ row.customer?.name }}</td>
      </ng-container>

      <!-- Start Datum Column -->
      <ng-container matColumnDef="assignment.startDate" style="width: 20%">
        <th scope="row" mat-header-cell *matHeaderCellDef mat-sort-header>Startdatum</th>
        <td mat-cell *matCellDef="let row">{{ row.assignment.startDate | date }}</td>
      </ng-container>

      <!-- End Datum Column -->
      <ng-container matColumnDef="assignment.endDate">
        <th scope="row" mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%">Einddatum</th>
        <td mat-cell *matCellDef="let row">{{ row.assignment.endDate | date }}</td>
      </ng-container>

      <!-- Edit Column -->
      <ng-container matColumnDef="edit">
        <th scope="row" mat-header-cell *matHeaderCellDef>Opties</th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button color="primary" (click)="editAssignment(row.assignment.id)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="primary" [disabled]="!isDeletable(row)" (click)="confirmDialog(row)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div *ngIf="error" style="margin: 1rem">Er was een fout bij het ophalen.</div>

    <div *ngIf="!error && !dataSource?.filteredData.length && !dataSource?.filter" style="margin: 1rem">
      Geen resultaten.
    </div>

    <div *ngIf="!error && dataSource?.filter && !dataSource?.filteredData.length" style="margin: 1rem">
      Geen resultaten uit de filter.
    </div>

    <mat-paginator [length]="dataSource.data.length" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
  </div>
</div>
