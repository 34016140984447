import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, FeedbackService, LoginService, UserService, PopupNewsService } from 'src/app/shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  username;
  password;
  hide = true;

  constructor(
    public feedbackService: FeedbackService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private loginService: LoginService,
    private popupNewsService: PopupNewsService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.authService.hasValidToken()) {
      this.loginService.logInWithExistingToken().then(() => this.landingPage());
    }
  }

  onSubmit(form: NgForm): void {
    this.login(form.value.username, form.value.password);
  }

  login(username: string, password: string): void {
    this.loginService.logIn(username, password).then(
      () => this.landingPage(),
      (error) => {
        //remove token
        sessionStorage.clear();

        console.error('login failed with error', error);
        this.feedbackService.openErrorToast(this.translateService.instant('login.failed'));
      },
    );
  }

  landingPage(): void {
    const redirect = this.activatedRoute.snapshot.queryParams.redirect;
    if (redirect) {
      this.router.navigateByUrl(redirect);
    } else if (this.userService.isEmployee()) {
      this.router.navigate(['medewerker', 'uren']);
    } else if (this.userService.isContentmanager()) {
      this.router.navigate(['content', 'custom-menu-item-list']);
    } else {
      this.router.navigate(['admin', 'urenoverzicht']);
    }
    this.popupNewsService.triggerPopupNewsByLogin();
  }

  forgot(): void {
    this.router.navigate(['forgot']);
  }
}
