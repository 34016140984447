import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';

import { AssignmentOverviewComponent } from './assignment-overview/assignment-overview.component';
import { MaterialModule } from '../../shared/material.module';
import { AssignmentDetailsComponent } from './assignment-details/assignment-details.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AssignmentOverviewComponent, AssignmentDetailsComponent],
  exports: [AssignmentOverviewComponent],
  imports: [CommonModule, MaterialModule, MatMenuModule, MatExpansionModule, PipesModule, TranslateModule],
})
export class AssignmentModule {}
