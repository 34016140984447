import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-submit-hours-dialog',
  templateUrl: './submit-hours-dialog.component.html',
  styleUrls: ['./submit-hours-dialog.component.scss'],
})
export class SubmitHoursDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SubmitHoursDialogComponent>) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
