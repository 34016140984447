import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Project } from 'src/app/shared/models';
import { FeedbackService, ProjectsService } from 'src/app/shared/services';

@Component({
  selector: 'app-projects',
  templateUrl: './projects-overview.component.html',
})
export class ProjectsOverviewComponent {
  displayedColumns: string[] = ['name', 'customerName', 'startDate', 'endDate', 'workFromHomeAllowed', 'edit'];

  constructor(
    public projectsService: ProjectsService,
    public dialog: MatDialog,
    public feedback: FeedbackService,
    private router: Router,
  ) {}

  editProject(row: Project): void {
    this.router.navigate(['/admin/project', row.id]);
  }

  addProject(): void {
    this.router.navigate(['admin', 'project']);
  }

  confirmDialog(row: Project): void {
    this.feedback.getDeleteConfirmation('Project ' + row.name).subscribe((dialogResult) => {
      this.onDialogResult(row.id, dialogResult);
    });
  }

  private onDialogResult(id, dialogResult) {
    if (dialogResult) {
      this.projectsService.delete(id).subscribe({
        next: () => {
          this.feedback.openSuccessToast('Project is verwijderd');
          this.router.navigate(['admin', 'projecten']);
        },
        error: (err) => {
          this.feedback.openErrorToast(err);
        },
      });
    }
  }
}
