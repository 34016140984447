import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersOverviewComponent } from './customers-overview/customers-overview.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { MaterialModule } from '../../shared/material.module';
import { MatExpansionModule } from '@angular/material/expansion';

import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { CustomerAddComponent } from './customer-add/customer-add.component';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { UraOverviewModule } from 'src/app/shared/components/ura-overview/ura-overview.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CustomersOverviewComponent, CustomerAddComponent, CustomerEditComponent],
  exports: [CustomersOverviewComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    NgxTrimDirectiveModule,
    MatExpansionModule,
    UraOverviewModule,
    TranslateModule,
  ],
})
export class CustomersModule {}
