import { Observable, ReplaySubject } from 'rxjs';
import { share } from 'rxjs/operators';

export function Memoized() {
  return function (target: unknown, propertyKey: string, descriptor: PropertyDescriptor): void {
    const method = descriptor.value; // references the method being decorated
    const cacheMember = propertyKey + 'CacheMember';
    // the Observable function
    if (!descriptor.value) {
      throw new Error('use MemoizeDecorator only on services methods');
    }

    descriptor.value = function (...args) {
      const targetName = `${cacheMember}-${args.toString()}`;
      if (!target[targetName]) {
        const returnedObservable = method.apply(this, args);
        if (!(returnedObservable instanceof Observable)) {
          throw new Error(`method decorated with Memoized Decorator must return Observable`);
        }

        target[targetName] = returnedObservable.pipe(
          share({
            connector: () => new ReplaySubject(1),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: false,
          }),
        );
      }

      return target[targetName];
    };
  };
}
