<div id="project-add">
  <h2>{{ "Project toevoegen" | translate}}</h2>
  <div class="header-wrapper">
    <mat-accordion multi>
      <form id="form" #form="ngForm" (ngSubmit)="dirty && !contentForm.invalid && submit()" [formGroup]="contentForm">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> {{ "Projectgegevens" | translate }} </mat-panel-title>
            <mat-panel-description>
              <mat-icon color="primary">folder_shared</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label>{{ "Naam" | translate }}</mat-label>
            <input
              type="text"
              matInput
              name="name"
              required
              (change)="dirty = true"
              (keyup)="keyup($event)"
              trim="blur"
              #name
              formControlName="name"
            />
            <mat-error>{{ "Veld moet worden ingevuld" | translate }}</mat-error>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "Klant" | translate }}</mat-label>
            <mat-select name="klant" (selectionChange)="dirty = true" formControlName="klant" required>
              <mat-option *ngFor="let customer of customers" [value]="customer"> {{ customer.name }} </mat-option>
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "Startdatum" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="startDate"
              name="startDate"
              required
              readonly
              (click)="startDate.open()"
              (dateChange)="dirty = true"
              formControlName="startDate"
            />
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
          <br />
          <mat-form-field>
            <mat-label>{{ "Einddatum" | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="endDate"
              name="endDate"
              readonly
              (click)="endDate.open()"
              (dateChange)="dirty = true"
              formControlName="endDate"
            />
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>
          <mat-error class="invalidDateRange" *ngIf="contentForm.errors?.invalidDateRange"
            >{{ "Start- en einddatum zijn incorrect." | translate }}
          </mat-error>
          <br />
          <mat-checkbox name="workFromHomeAllowed" placeholder="Einddatum" formControlName="workFromHomeAllowed">
            {{ "Thuiswerken toegestaan" | translate }}
          </mat-checkbox>
        </mat-expansion-panel>
        <div class="actions">
          <button type="reset" mat-raised-button (click)="cancel()">
            <mat-icon>close</mat-icon>
            {{ "Annuleren" | translate }}
          </button>
          <button
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!dirty || contentForm.invalid"
            form="form"
          >
            <mat-icon>add</mat-icon>
            <span translate> {{ "Toevoegen" | translate }} </span>
          </button>
        </div>
      </form>
    </mat-accordion>
  </div>
</div>
