import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CustomMenuItemsComponent } from './custom-menu-items/custom-menu-items.component';
import { CustomMenuItemListComponent } from './custom-menu-items/custom-menu-item-list/custom-menu-item-list.component';
import { CustomMenuItemDetailComponent } from './custom-menu-items/custom-menu-item-detail/custom-menu-item-detail.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { UraOverviewModule } from '../shared/components/ura-overview/ura-overview.module';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
@NgModule({
  declarations: [CustomMenuItemsComponent, CustomMenuItemListComponent, CustomMenuItemDetailComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatExpansionModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    UraOverviewModule,
    NgxTrimDirectiveModule,
  ],
  exports: [CustomMenuItemsComponent],
})
export class ContentModule {}
