import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { News } from 'generated-sources';
import { SharepointService } from 'src/app/shared/services';

@Component({
  selector: 'app-news-dialog',
  templateUrl: './news-dialog.component.html',
  styleUrls: ['./news-dialog.component.scss'],
})
export class NewsDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public newsItems: News[],
    public dialogRef: MatDialogRef<NewsDialogComponent>,
    private sharepointService: SharepointService,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onLinkClick(newsItemId: number): void {
    this.sharepointService.markAsSeen(newsItemId).subscribe({
      next: () => {
        // success
      },
      error: () => {
        // snackBar
      },
    });
  }
}
