export const TIMEOUT = 7000;

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share, timeout } from 'rxjs/operators';
import { PendingService } from './pending.service';
import { Injectable } from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types */
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private pendingService: PendingService,
    private http: HttpClient,
  ) {}

  public get asClient(): HttpClient {
    return this as unknown as HttpClient;
  }

  public request<T>(method: string, url: string, options: any): Observable<any> {
    switch (method.toLowerCase()) {
      case 'get': {
        return this.get(url, options);
      }
      case 'post': {
        return this.post(url, options?.body, options);
      }
      case 'put': {
        return this.put(url, options?.body, options);
      }
      case 'delete': {
        return this.delete(url, options);
      }
    }
  }

  public get<T>(url: string, params?): Observable<T> {
    return this.pendingService.intercept(
      'GET',
      url,
      params,
      this.http.get(url, params).pipe(timeout(TIMEOUT)).pipe(share()),
    );
  }

  public post<T>(url: string, body: any, options?): Observable<any> {
    return this.http
      .post(url, body, options ? options : this.httpOptions)
      .pipe(timeout(TIMEOUT))
      .pipe(share());
  }

  public put<T>(url: string, body: any, options?): Observable<any> {
    return this.http
      .put(url, body, options ? options : this.httpOptions)
      .pipe(timeout(TIMEOUT))
      .pipe(share());
  }

  public delete(url: string, options?): Observable<any> {
    return this.http
      .delete(url, options ? options : this.httpOptions)
      .pipe(timeout(TIMEOUT))
      .pipe(share());
  }
}
