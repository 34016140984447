import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

import { MaterialModule } from '../../shared/material.module';
import { ProjectsOverviewComponent } from './projects-overview/projects-overview.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ProjectAddComponent } from './project-add/project-add.component';
import { ProjectEditComponent } from './project-edit/project-edit.component';
import { UraOverviewModule } from 'src/app/shared/components/ura-overview/ura-overview.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ProjectsOverviewComponent, ProjectAddComponent, ProjectEditComponent],
  exports: [ProjectsOverviewComponent],
  imports: [CommonModule, MaterialModule, MatExpansionModule, PipesModule, UraOverviewModule, TranslateModule],
})
export class ProjectsModule {}
