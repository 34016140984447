import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { EmployeesService } from './employees.service';
import { CustomersService } from './customers.service';
import { ProjectsService } from './projects.service';
import { AssignmentsService } from './assignments.service';
import { LoadingScreenService } from './loading-screen.service';
import { LoadingScreenInterceptorService } from './loading-screen-interceptor.service';
import { ErrorInterceptorService } from './error-interceptor.service';
import { FeedbackService } from './feedback.service';
import { EffortsService } from './efforts.service';
import { ApiModule } from 'generated-sources';

const services = [
  EmployeesService,
  CustomersService,
  ProjectsService,
  AssignmentsService,
  LoadingScreenService,
  FeedbackService,
  EffortsService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingScreenInterceptorService,
    multi: true,
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ApiModule],
  providers: [...services],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [...services],
    };
  }
}
