import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Employee } from 'src/app/shared/models';

@Component({
  selector: 'app-generated-password-dialog',
  templateUrl: './generated-password-dialog.component.html',
})
export class GeneratedPasswordDialogComponent {
  public copied = false;

  constructor(
    public dialogRef: MatDialogRef<GeneratedPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Employee,
  ) {}

  ok(): void {
    this.dialogRef.close();
  }

  copy(inputElement: HTMLInputElement): void {
    inputElement.select();
    navigator.clipboard.writeText(inputElement.value).then(
      () => (this.copied = true),
      () => (this.copied = false),
    );
  }

  email(): void {
    const subject = encodeURIComponent('Welkom, nieuwe collega!');
    const body = encodeURIComponent(
      'Welkom ' +
        this.data.name +
        '\n\n Welkom bij XLence! Om je eerste dagen bij XLence en je opdracht zo soepel mogelijk van start te laten gaan, informeren we je met deze email over een aantal zaken die jou en XLence hierbij gaan helpen. Mocht je toch nog vragen hebben, dan kunt je contact opnemen met de administratie van XLence (Marie-Louise Lakeman), bereikbaar per telefoon op nummer 033-2455083 of per mail via marielouise.lakeman@xlence.nl.' +
        '\n\n Maandstaat \n\n Voor de urenverantwoording dien je gebruik te maken van het urenregistratiesysteem van de opdrachtgever. Een getekende of geautoriseerde uitdraai of printscreen uit dit urenregistratiesysteem dien je uiterlijk de 2e werkdag van de volgende maand te mailen naar uren@xlence.nl.' +
        '\n\n Daarnaast dien je je uren in te voeren in URA (op ura.codus.nl), hiervoor ontvang je bij deze de inloggegevens:' +
        '\n\n Gebruikersnaam  : ' +
        this.data.userName +
        '\n Wachtwoord        : ' +
        this.data.generated +
        '\n\n Registreer je uren bij voorkeur wekelijks of dagelijks zodat je hier niet aan het einde van de maand veel tijd mee kwijt bent en mogelijk niet meer exact kunt reproduceren wanneer en hoe lang je hebt gewerkt.' +
        '\n\n Met vriendelijke groet,\n\nXlence People',
    );
    window.location.href = 'mailto:' + this.data.email + '?subject=' + subject + '&body=' + body;
  }
}
