import { Directive, ElementRef, HostListener, Input, AfterViewChecked } from '@angular/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: 'input[appMatInputMinutyfied]',
  providers: [{ provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputMinutyfiedDirective }],
})
export class MatInputMinutyfiedDirective implements AfterViewChecked, ControlValueAccessor {
  private _value: string | null;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  get value(): string | null {
    return this._value;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('value')
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appMatInputMinutyfied') decimalValue: { hourPart: number; minutePart: number };

  private formatValue(value: string | null) {
    if (!(this.decimalValue?.hourPart || +this.elementRef.nativeElement.value > 0)) {
      this.elementRef.nativeElement.value = '';
    } else if (value) {
      this.elementRef.nativeElement.value = +value < 10 ? `0${value}` : value;
    } else {
      this.elementRef.nativeElement.value = '00';
    }
  }

  private unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    if (value) {
      this._value = value.replace(/^0/, '');
      this.elementRef.nativeElement.value = this._value;
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this._value = value.replace(/^0/, '');
    this._onChange();
  }

  @HostListener('blur')
  _onBlur(): void {
    this.formatValue(this._value); // add zero
  }

  @HostListener('focus')
  onFocus(): void {
    this.unFormatValue(); // remove zero for editing purpose
  }

  _onChange(): void {
    // No implementation required
  }

  writeValue(value: string): void {
    this._value = value;
    this.formatValue(this._value); // format Value
  }

  registerOnChange(fn: () => void): void {
    this._onChange = fn;
  }

  ngAfterViewChecked(): void {
    if (this.elementRef.nativeElement.value) {
      this.formatValue(this.elementRef.nativeElement.value.replace(/^0/, ''));
    }
  }

  registerOnTouched(): void {
    // No implementation required
  }
}
