<div id="confirm-dialog" mat-dialog-content>
  <p>Weet u zeker dat u {{ info }} wilt {{ confirmText | lowercase }}?</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="onDismiss()">
    <mat-icon>close</mat-icon>
    Annuleren
  </button>
  <button mat-raised-button color="primary" (click)="onConfirm()">
    <mat-icon *ngIf="useDelete">delete</mat-icon>
    <mat-icon *ngIf="useArchiving">archive</mat-icon>
    {{ confirmText }}
  </button>
</div>
