import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class ArraySortPipe implements PipeTransform {
  transform<T>(array: T[], field: string): T[] {
    if (!Array.isArray(array)) return;

    array.sort((a: T, b: T) => {
      const lf = typeof a[field] === 'function';
      const rf = typeof b[field] === 'function';

      const left = lf ? a[field]() : a[field];
      const right = rf ? b[field]() : b[field];

      if (left < right) {
        return -1;
      } else if (left > right) {
        return 1;
      } else {
        return 0;
      }
    });

    return array;
  }
}
