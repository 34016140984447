import { Customer } from './customer';
import { Model } from './model';
import moment, { Moment } from 'moment';
import { Project as ProjectDTO } from 'generated-sources';

export class Project extends Model {
  name: string;
  customerId: number;
  startDate: Moment;
  endDate: Moment;
  deletable: boolean;
  workFromHomeAllowed: boolean;

  private _customer?: Customer;

  constructor(dto?: ProjectDTO) {
    super();
    if (dto) {
      this.id = dto.id;
      this.name = dto.name;
      this.customerId = dto.customer?.id;
      this.customer = dto.customer ? new Customer(dto.customer) : null;
      this.startDate = moment(dto.startDate);
      this.endDate = dto.endDate ? moment(dto.endDate) : null;
      this.deletable = !!dto.deletable;
      this.workFromHomeAllowed = dto.workFromHomeAllowed;
    }
  }

  public get startDateString(): string {
    return Model.formatDate(this.startDate);
  }

  public get endDateString(): string {
    return this.endDate?.isValid() ? Model.formatDate(this.endDate) : null;
  }

  public get customer(): Customer {
    return this._customer;
  }

  public set customer(c: Customer) {
    this._customer = c;
    this.customerId = c?.id;
  }
}
