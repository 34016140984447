import { Model } from './model';
import moment, { Moment } from 'moment';
import { ProjectListObject as ProjectListObjectDTO } from 'generated-sources';

export class ProjectListObject extends Model {
  public name: string;
  customerId: number;
  customerName: string;
  startDate: Moment;
  endDate: Moment;
  workFromHomeAllowed;

  constructor(raw: ProjectListObjectDTO) {
    super();
    this.id = raw.id;
    this.name = raw.name;
    this.customerId = raw.customerId;
    this.customerName = raw.customerName;
    this.startDate = moment(raw.startDate);
    this.endDate = raw.endDate ? moment(raw.endDate) : null;
    this.workFromHomeAllowed = raw.workFromHomeAllowed;
    this.deletable = !!raw.deletable;
  }
}
