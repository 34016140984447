import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { LoginService, MenuService, UserService, MenuItem, PopupNewsService } from 'src/app/shared/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { appRoutes } from 'src/app/app.routes';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Output() closeSidenav = new EventEmitter<void>();
  private activeMenuItemsSubject: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  public activeMenuItems$: Observable<MenuItem[]> = this.activeMenuItemsSubject.asObservable();
  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private router: Router,
    private readonly menuService: MenuService,
    private popupNewsService: PopupNewsService,
  ) {}

  ngOnInit(): void {
    this.activeMenuItemsSubject.next(this.setupMenuItemsViaService());
  }
  private setupMenuItemsViaService(): MenuItem[] {
    const menuItems = this.menuService.getMenuFromRoute(appRoutes);
    if (this.isEmployee) {
      menuItems.push(this.createEmployeeMonthOverviewItem());
    }
    menuItems.push(this.createPopMenuNewsItem());
    menuItems.push(this.createLogoutMenuItem());
    return menuItems;
  }

  private createEmployeeMonthOverviewItem(): MenuItem {
    return {
      icon: 'recent_actors',
      translateKey: 'menu.month-overview',
      onClick: this.onMaandoverzichtClicked.bind(this),
    };
  }

  private createPopMenuNewsItem(): MenuItem {
    return {
      icon: 'newspaper',
      translateKey: 'menu.news',
      onClick: this.openPopupNews.bind(this),
    };
  }

  private createLogoutMenuItem(): MenuItem {
    return {
      icon: 'exit_to_app',
      translateKey: 'menu.logout',
      onClick: this.logOut.bind(this),
    };
  }

  onClose(): void {
    this.closeSidenav.emit();
  }

  onMaandoverzichtClicked(): void {
    const now = moment().subtract(1, 'months');
    const loggedInUser = this.userService.getLoggedInUser();
    this.closeSidenav.emit();
    this.router.navigate(['medewerker', 'urenoverzicht', loggedInUser.id, now.year(), now.month() + 1]);
  }

  get isAdministrator(): boolean {
    return this.userService.isAdministrator();
  }

  get isContentmanager(): boolean {
    return this.userService.isContentmanager();
  }

  get isAccounting(): boolean {
    return this.userService.isAccounting();
  }

  get isEmployee(): boolean {
    return this.userService.isEmployee();
  }

  openPopupNews() {
    this.popupNewsService.triggerPopupNewsByMenu();
  }

  logOut(): void {
    this.loginService.logOut();
  }
}
