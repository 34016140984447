<app-ura-overview
  [entityType]="'employee'"
  [dataObservable]="employeeService.getAll()"
  [displayedFields]="displayedColumns"
  [useArchiving]="true"
  (add)="addEmployee()"
  (edit)="editEmployee($event)"
  (delete)="confirmDialog($event)"
  (overview)="linkHourOverview($event)"
>
</app-ura-overview>
