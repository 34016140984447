<div id="holiday-hours-dialog" mat-dialog-content>
  <p translate>hoursoverview.popup-message</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="onDismiss()">
    <mat-icon>close</mat-icon>
    Annuleren
  </button>
  <button mat-raised-button color="primary" (click)="onConfirm()">
    <mat-icon>face</mat-icon>
    Employee
  </button>
</div>
