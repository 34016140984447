import { Injectable } from '@angular/core';
import 'moment/locale/nl';

@Injectable()
export class LocaleService {
  getLocale(): string {
    // supported languages
    const languages = {
      nl: 'Nederlands',
      en: 'English',
    };

    const locale = navigator?.languages[0] || navigator.language;
    const detectedLanguage = locale.substring(0, 2);

    return languages[detectedLanguage] ? detectedLanguage : 'nl';
  }
}
