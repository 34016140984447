import { Routes } from '@angular/router';
import { CustomMenuItemListComponent } from './custom-menu-items/custom-menu-item-list/custom-menu-item-list.component';
import { CustomMenuItemDetailComponent } from './custom-menu-items/custom-menu-item-detail/custom-menu-item-detail.component';

export const contentRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'custom-menu-item-list',
        component: CustomMenuItemListComponent,
        data: {
          icon: 'category',
          menuTranslateKey: 'menu.manage-items',
        },
      },
      {
        path: 'custom-menu-item-detail',
        component: CustomMenuItemDetailComponent,
      },
      {
        path: 'custom-menu-item-detail/:id',
        component: CustomMenuItemDetailComponent,
      },
    ],
  },
];
