<div id="sidenav">
  <mat-nav-list class="sidenav">
    <ng-container *ngFor="let menuItem of activeMenuItems$ | async; let last = last">
      <span *ngIf="last"><app-custom-menu-items></app-custom-menu-items></span>
      <a mat-list-item [routerLink]="menuItem.path" (click)="(menuItem.onClick) ? menuItem.onClick() : onClose()">
        <mat-icon class="icon-white" matListItemIcon>{{menuItem.icon}}</mat-icon>
        <span class="nav-caption" matListItemLine translate>{{menuItem.translateKey}}</span>
      </a>
    </ng-container>
  </mat-nav-list>
</div>
