import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, MaybeAsync, GuardResult } from '@angular/router';
import { UserService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private readonly userService: UserService) {}
  //eslint-disable-next-line sonarjs/function-return-type
  canActivate(next: ActivatedRouteSnapshot): MaybeAsync<GuardResult> {
    return this.userService.hasRoles(next.data.roleNeeded);
  }
}
